/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable no-script-url */
import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { axios, endpoints, baseUrl } from 'Api';
import { AppContext, AuthContext } from 'Context';
import { Pathname } from 'Routes';
import { ReactComponent as AddIcon } from 'Assets/add-circle.svg';
import { ReactComponent as MenuIcon } from 'Assets/menu.svg';
import { ReactComponent as VideoUploadIcon } from 'Assets/video-upload.svg';
import { ReactComponent as ProfileIcon } from 'Assets/profile.svg';
import { ReactComponent as PersonIcon } from 'Assets/person-profile.svg';
import { ReactComponent as HomeIcon } from 'Assets/home-header.svg';
import { ReactComponent as SearchIcon } from 'Assets/search.svg';
import { ReactComponent as WatchlistIcon } from 'Assets/watchlistIcon.svg';
import AlterImage from 'Assets/alter-image.png';
import Spinner from 'Components/Spinner';
import { toast } from 'react-toastify';
import ArrowBackIos from 'Assets/backArrowIcon.svg';
import { ALLOWED_UPLOADS_COUNT, PLANS } from 'Commons';
import useSWR from 'swr';
import ReactNotification, { store } from 'react-notifications-component';
import DehazeIcon from '@material-ui/icons/Dehaze';
import NotificationSound from 'Components/NotificationSound';
import { VideoPopupModal } from 'Components/VideoPopup';
import { useSelector } from 'react-redux';
import { ref, update } from 'firebase/database';
import { saveUploadInfo, clearAllDummys } from 'store/store';
import Tooltip from '@mui/material/Tooltip';
import MessageComponent from '../../MessageComponent';
import Notifications from './components/Notifications';
import { Logo } from '../logo';
import { ShareScreen } from '../share-screen';
import { UploadModal } from '../upload-modal';
import styles from './index.module.css';
import 'react-notifications-component/dist/theme.css';
import { Drawer, Menu } from './components/CustomDesigns';
import ProgressBox from '../progressBox';
import { HeaderIconButton, ProfileMenuProfileButton, ProfileDrawerProfileButton } from './components/HeaderIconButton';
import { InfoModal } from '../../Pages/private/dashboard/info-modal';
import CreateGroup from './components/createGroup';
import { VideoBanner } from './components/videoBanner';
import VideoUploadProgress from './components/VideoUploadProgress';
import BasicAccordion from './BasicAccordion';

export const Header = ({
  glassCompactHeaderBG,
  isProfileHeader,
  hideMenu,
  noHeader,
  noRegularHeader,
  onMenuClick,
  transparentCompactHeader,
  userName,
  userProfileLink,
  headerTheme,
  videoBanner,
  layoutData
}) => {
  const { userConnections, userDetails, getUserConnections, langText, setViewerListUpdate, draftcontent,
    getAccessChecker, apiResponse, fetchData, autoSignIn, uploaderLoader, setUploaderLoader, firebaseDatabase,
    setUploadContainer, profileDetails, setProfileDetails, subscriptionDetails, showProgressBox,
    mangerFunctionData, setmangerFunctionData, messaged, setdataOfCategory,
    amICalled, setamICalled, setProfileLoader, projectorOwner, projectorImage, activeUploads, setAppSnackbar, setSelectedItemsInBulk, setVideoGroup } = useContext(AppContext);
  const { removeToken, setUserSignInStatus, isInActiveUser, gettingSubscription, removeEmailVerified } = useContext(AuthContext);
  const headerText = langText?.header;
  const commonText = langText?.common;
  const contentDashboard = langText?.contentDashboard;
  const [profileName, setProfileName] = useState(undefined);
  const [errorMsg, setErrorMsg] = useState(undefined);
  const location = useLocation();
  const navigate = useNavigate();
  const { profileID } = useParams();
  const afflitedRes = apiResponse?.data ? apiResponse?.data?.length : 0;
  const [dropDown, setDropDown] = useState(false);
  const clearCache = () => {
    const cookies = document.cookie.split('; ');
    cookies.forEach((cookie) => {
      const [name] = cookie.split('=');
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    });
    // Clear local storage
    localStorage.clear();
    // Clear session storage
    sessionStorage.clear();
    // Clear cache (for some browsers)
    if (window.caches) {
      window.caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          window.caches.delete(cacheName);
        });
      });
    }
  };
  const updateUploadPercentages = (draft_id, data) => {
    dispatch(saveUploadInfo({ [draft_id]: data }));
  };
  useEffect(() => {
    fetchData();
  }, []);
  const getToken = () => localStorage.getItem('token');
  const databaseRef = ref(firebaseDatabase, 'status_from_sns');
  const changeFieldFromFirebase = (draft_id, statusString) => {
    const dataToUpdate = {
      [draft_id]: {
        status: statusString,
      },
    };
    update(databaseRef, dataToUpdate);
  };
  const closeToast = (draft_id, message, type) => {
    toast.update(draft_id, { closeButton: false, render: message, type: type === 'success' ? toast.TYPE.SUCCESS : toast.TYPE.ERROR, autoClose: 3000 });
    setUploadContainer((prevUploadContainer) => prevUploadContainer.filter((id) => id !== draft_id));
  };

  const updateDummy = (dummy, status) => {
    if (status === 1) {
      dispatch(saveDummy({ [dummy?.draft_id]: dummy }));
    } else {
      dispatch(removeDummy(dummy));
    }
  };
  const clearAllDummy = () => {
      dispatch(clearAllDummys());
  };
  const handleEncouter = (draft_id) => {
    const deletePromise = axios
          .post(`${baseUrl}/deleteDraftContent`, {
            token: getToken(),
            draft_id: [draft_id],
            content_id: [],
          })
          .then((response) => {
            changeFieldFromFirebase(draft_id, 'DELETED');
            updateDummy(draft_id, 0);
            updateUploadPercentages(draft_id, 'Cancelled');
            closeToast(draft_id, 'Upload has been canceled by user', 'error');
            return response;
          })
          .catch((error) => {
            updateDummy(draft_id, 0);
            console.error(`Error deleting items for draftId ${draft_id}:`, error);
            throw error;
          });
  };
  const uploadPercentages = useSelector((state) => state.upload);
  const logout = () => {
    const filteredKeys = Object.keys(uploadPercentages).filter(
      (key) => typeof uploadPercentages[key] === 'number'
      || uploadPercentages[key] === 'Starting Upload'
      || uploadPercentages[key] === 'Initializing'
      );
    if (filteredKeys?.length > 0) {
      filteredKeys.forEach((draft_id) => {
        handleEncouter(draft_id);
      });
    }
    clearCache();
    removeToken();
    setUserSignInStatus(false);
    removeEmailVerified();
    clearAllDummy();
  };
  const links = isInActiveUser ? [
    { name: headerText?.dropDown?.allProjector || 'All Projectors', link: Pathname.access },
    { name: headerText?.dropDown?.contentDashboard || 'Content Dashboard', link: Pathname.dashboard },
    { name: headerText?.dropDown?.accountSetting || 'Account Settings', link: Pathname.getSettingsPath(Pathname.settings.billings) },
    { name: headerText?.dropDown?.terms || 'Privacy and Terms', link: Pathname.getSettingsPath(Pathname.settings.privacy), target: '_blank' },
    { name: headerText?.dropDown?.helpCenter || 'Help Center', link: 'https://projectorstream.zendesk.com/hc/en-us', type: 'help', target: '_blank' },
    { name: headerText?.dropDown?.logOut || 'Log Out', link: Pathname.authentication.newsignIn, onClick: () => { logout(); } },
  ] : afflitedRes === 0 ? [
    { name: headerText?.dropDown?.allProjector || 'All Projectors', link: Pathname.access },
    { name: headerText?.dropDown?.contentDashboard || 'Content Dashboard', link: Pathname.dashboard },
    { name: headerText?.dropDown?.accountSetting || 'Account Settings', link: Pathname.getSettingsPath(Pathname.settings.accounts) },
    { name: headerText?.dropDown?.tvLogin || 'TV Login', link: Pathname.tvLogin },
    { name: headerText?.dropDown?.terms || 'Privacy and Terms', link: Pathname.getSettingsPath(Pathname.settings.privacy), target: '_blank' },
    // { name: headerText?.dropDown?.termsOfService || 'Terms of Service', link: Pathname.getSettingsPath(Pathname.termsAndConditions), target: '_blank' },
    // { name: headerText?.dropDown?.subscriberAgreement || 'Subscriber Agreement', link: Pathname.getSettingsPath(Pathname.settings.subscriberAgreement), target: '_blank' },
    { name: headerText?.dropDown?.helpCenter || 'Help Center', link: 'https://projectorstream.zendesk.com/hc/en-us', type: 'help', target: '_blank' },
    { name: headerText?.dropDown?.logOut || 'Log Out', link: Pathname.authentication.newsignIn, onClick: () => { logout(); } },
  ] : [
    { name: headerText?.dropDown?.allProjector || 'All Projectors', link: Pathname.access },
    { name: headerText?.dropDown?.contentDashboard || 'Content Dashboard', link: Pathname.dashboard },
    { name: headerText?.dropDown?.accountSetting || 'Account Settings', link: Pathname.getSettingsPath(Pathname.settings.accounts) },
    { name: headerText?.dropDown?.tvLogin || 'TV Login', link: Pathname.tvLogin },
    { name: headerText?.dropDown?.terms || 'Privacy and Terms', link: Pathname.getSettingsPath(Pathname.settings.privacy), target: '_blank' },
    { name: headerText?.dropDown?.helpCenter || 'Help Center', link: 'https://projectorstream.zendesk.com/hc/en-us', type: 'help', target: '_blank' },
    // { name: 'User Management', typeOf: 'dropdown', onClick: () => setDropDown(!dropDown) },
    { name: 'User Management', typeOf: 'dropdown', link: '/settings/manage-users' },
    { name: headerText?.dropDown?.logOut || 'Log Out', link: Pathname.authentication.newsignIn, onClick: () => { logout(); } },
  ];

  const rightLinks = [
    { name: headerText?.dropDown?.contentDashboard || 'Content Dashboard', link: Pathname.dashboard },
    { name: headerText?.dropDown?.accountSetting || 'Account Settings', link: isInActiveUser ? Pathname.getSettingsPath(Pathname.settings.billings) : Pathname.getSettingsPath(Pathname.settings.accounts) },
    // { name: headerText?.editProfile || 'Edit Profile', link: Pathname.getSettingsPath(Pathname.settings.accounts) },
    { name: headerText?.dropDown?.logOut || 'Log Out', link: Pathname.authentication.newsignIn, onClick: () => { logout(); } },
  ];
  const profiles = userConnections;
  const [uploadModalIsVisible, setUploadModalVisibility] = useState(false);
  const [popUpModalVisible, setPopUpModalVisible] = useState(false);
  const [accessRequestModalIsVisible, setAccessRequestModalVisibility] = useState(false);
  const [accessGrantModalIsVisible, setAccessGrantModalVisibility] = useState(false);
  const [referFriendModalIsVisible, setReferFriendModalVisibility] = useState(false);

  const [profileMenuIsVisible, setProfileMenuVisibility] = useState(false);
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
  const [pageHasScrolled, setPageScroll] = useState(false);
  const [menuItem, setMenuItem] = useState('');
  const [clearing, setClearing] = useState(false);
  const [processingInfo, setProcessingInfo] = useState(false);

  const [userList, setUserList] = useState(profiles);
  const [isStorageFull, setStorageFull] = useState(false);
  const [isOpenPopupModal, setOpenPopupModal] = useState(false);
  const [selectedTab, selectTab] = useState('home');
  const [isMouseOver, setMouseOver] = useState(null);
  const storageFullCondition = parseInt(subscriptionDetails?.storageUsed?.used_storage, 10) === parseInt(subscriptionDetails?.storageUsed?.total_storage, 10);
  const viewerLimitCondition = parseInt(subscriptionDetails?.subscription?.is_unlimited, 10) !== 1 && parseInt(subscriptionDetails?.total_viewers, 10) >= parseInt(subscriptionDetails?.subscription?.max_view_request, 10);
  const getFriendProfile = async () => {
    setProfileLoader(true);
    await axios
      .post(endpoints.getUserContents, { user_id: profileID })
      .then(({ data }) => {
        if (data.success) {
          setProfileDetails({
            firstname: data.freind.firstname,
            lastname: data.freind.lastname,
            email: data.freind.email,
            image: data.freind.image
          });
          setProfileName(data.freind?.firstname?.substring(0, 10) || data.freind?.email?.split('@')[0]?.substring(0, 10));
        } else {
          setProfileDetails({
            firstname: userDetails.firstname,
            lastname: userDetails.lastname,
            email: userDetails.email,
            image: userDetails.dpURL
          });
          setProfileName(userDetails.firstname?.substring(0, 10) || userDetails.email?.split('@')[0]?.substring(0, 10));
        }
      })
      .catch((error) => {
        setProfileDetails({
          firstname: userDetails.firstname,
          lastname: userDetails.lastname,
          email: userDetails.email,
          image: userDetails.dpURL
        });
        setProfileName(userDetails.firstname?.substring(0, 10) || userDetails.email?.split('@')[0]?.substring(0, 10));
      });
      setProfileLoader(false);
  };

  // useEffect(() => {
  //   if (uploadModalIsVisible) document.body.classList.add('modal-open');
  //   else document.body.classList.remove('modal-open');
  //   const handleWheel = (event) => {
  //     if (uploadModalIsVisible) {
  //       event.preventDefault();
  //     }
  //   };
  //   document.addEventListener('wheel', handleWheel, { passive: false });
  //   return () => {
  //     document.removeEventListener('wheel', handleWheel);
  //   };
  // }, [uploadModalIsVisible]);

  const updateYourProfileDetails = () => {
    const newData = profiles.slice(0);
    const yourProjector = {
      firstname: (headerText?.your || 'Your'),
      lastname: (headerText?.projector || 'Projector'),
      email: userDetails.email,
      image: userDetails.dpURL,
      type: 'self'
    };
    newData.splice(0, 0, yourProjector);
    setUserList(newData);
  };
  const hideProfileMenu = () => { setProfileMenuAnchorEl(null); setProfileMenuVisibility(false); };
  const updateUserDetails = (firstname, lastname, email, id, image, type) => {
    setdataOfCategory();
    setamICalled(!amICalled);
    if (type === 'self') {
      setProfileDetails({
        firstname: userDetails.firstname,
        lastname: userDetails.lastname,
        email: userDetails.email,
        image: userDetails.dpURL
      });
      setProfileName(userDetails.firstname?.substring(0, 10) || userDetails.email?.split('@')[0]?.substring(0, 10));
    } else {
      setProfileDetails({ firstname, lastname, email, image });
    }
    hideProfileMenu();
  };
  useEffect(() => {
    if (profileID) {
      getFriendProfile();
    } else {
      setProfileDetails({
        firstname: userDetails.firstname,
        lastname: userDetails.lastname,
        email: userDetails.email,
        image: userDetails.dpURL
      });
      setProfileName(userDetails.firstname?.substring(0, 10) || userDetails.email?.split('@')[0]?.substring(0, 10));
    }
  }, [profileID]);
  useEffect(() => { updateYourProfileDetails(); }, [profiles]);

  // hide upload modal after multiple files upload started
  useEffect(() => {
    if (showProgressBox) {
      setUploadModalVisibility(false);
    }
  }, [showProgressBox]);
  const revealUploadModal = () => {
    setVideoGroup([]);
    setUploaderLoader(true);
    localStorage.setItem('opened_draft_id', null);
        // setUploadModalVisibility(true);
    autoSignIn().then((res) => {
      if (!res?.data?.subscription && res?.data?.totalContentUploaded >= ALLOWED_UPLOADS_COUNT) {
        setPopUpModalVisible(true);
      } else {
        setUploadModalVisibility(true);
      }
    });
  };
  const hideUploadModal = () => {
    setUploadModalVisibility(false);
    // navigate(location.pathname);
  };
  const hidepopModal = () => { setPopUpModalVisible(false); };
  const revealAccessRequestModal = () => {
    // if (subscriptionDetails ? subscriptionDetails.subscription === 'Free' : false) {
    //   setAppSnackbar({ isVisible: true, type: 'error', message: 'You must have active plan to a profile' });
    //   navigate(Pathname.plans);
    // } else {
    //   setAccessRequestModalVisibility(true);
    // }
    setAccessRequestModalVisibility(true);
  };
  const hideAccessRequestModal = () => { setAccessRequestModalVisibility(false); navigate(location.pathname); };

  const revealAccessGrantModal = () => {
    setAccessGrantModalVisibility(true);
  };
  const hideAccessGrantModal = () => { setAccessGrantModalVisibility(false); navigate(location.pathname); };
  const revealProfileMenu = (dropDownData) => (e) => {
    setProfileMenuAnchorEl(e.currentTarget);
    setProfileMenuVisibility(true);
    setMenuItem(dropDownData);
  };
  const revealReferFriendModal = () => {
    setReferFriendModalVisibility(true);
  };

  const hideInviteFriendModal = () => {
    setReferFriendModalVisibility(false);
    navigate(location.pathname);
  };
  useEffect(() => {
    if (location.pathname?.includes('search')) {
      selectTab('search');
    } else if (location.pathname?.includes('watchlist')) {
      selectTab('watchlist');
    } else if (location.pathname?.includes('profile')) {
      selectTab('home');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.search?.includes(Pathname.uploadVideo)) {
      revealUploadModal();
    } else if (location.search?.includes(Pathname.requestAccess)) {
      revealAccessRequestModal();
    } else if (location.search?.includes(Pathname.grantAccess)) {
      if (viewerLimitCondition) {
        setStorageFull(true); navigate(location.pathname);
      } else {
        revealAccessGrantModal();
      }
    } else if (location.search?.includes(Pathname.referFriend)) {
      revealReferFriendModal();
    } else if (location.search?.includes(Pathname.upgradePlan) /* || location.search?.includes(Pathname.processingInBackGround) */) {
      setStorageFull(true); navigate(location.pathname);
    }
    // if (location.search?.includes(Pathname.processingInBackGround)) {
    //   setProcessingInfo(true);
    // }
  }, [location.search]);
  useEffect(() => {
    const scrollHandler = () => {
      if ((document.body.scrollTop > 0) || (document.documentElement.scrollTop > 0)) {
        setPageScroll(true);
      } else {
        setPageScroll(false);
      }
    };

    window.addEventListener('scroll', scrollHandler);

    return () => { window.removeEventListener('scroll', scrollHandler); };
  }, []);
  const grantProfileAccess = async ([onHide, setActionStatus, setOperationStatus, setSelectedUsers, setUserList, users]) => {
    setActionStatus(true);
    const uniqueUsers = users.filter((user, index, self) => index === self.findIndex((u) => u.email === user.email));
    const { data: grantAccessData } = await axios
      .post(endpoints.addViewer, {
        data: uniqueUsers.map((data) => ({ ...data, firstName: data.firstname, lastName: data.lastname }))
      })
      .catch(() => ({}));
    setUserList([]);
    if (grantAccessData?.success) {
      setSelectedUsers([]);
      setOperationStatus(true);
      onHide();
      setViewerListUpdate(true);
    } else {
      setOperationStatus(false);
    }
    setActionStatus(false);
  };

  const sendInvitation = async ([onHide, setActionStatus, setOperationStatus, setSelectedUsers, setUserList, users]) => {
    setActionStatus(true);
    const response = await axios
      .post(endpoints.sendReferralInvitation, {
        email: users[0].email
      })
      .catch((error) => {
        setErrorMsg(error?.response?.data?.message);
        setOperationStatus(false);
      });
    setUserList([]);
    if (response?.data?.success) {
      setSelectedUsers([]);
      setOperationStatus(true);
      onHide();
    } else {
      setOperationStatus(false);
    }
    setActionStatus(false);
  };
  const requestProfileAccess = async ([onHide, setActionStatus, setOperationStatus, setSelectedUsers, setUserList, users]) => {
    setActionStatus(true);

    const { data: requestProfileAccessData } = await axios
      .post(endpoints.sendViewRequest, { users: `${users.map((userData) => userData.id)}` })
      .catch(() => ({}));

    setUserList([]);

    if (requestProfileAccessData?.success) {
      setSelectedUsers([]);
      setOperationStatus(true);
      onHide();
    } else {
      setOperationStatus(false);
    }

    setActionStatus(false);
  };
  const updateNotificationStatus = async (id, read_all, getMyNotifications) => {
    setClearing(true);
    await axios
      .post(endpoints.updateMyNotifications, { id, read_all, status: '2' })
      .then((response) => {
        getMyNotifications();
        // setClearing(false);
      })
      .catch((error) => {
        setClearing(false);
      });
  };

  const handlePushNotification = ({ id, message, status }) => {
    // updateNotificationStatus(id, '3');
    updateNotificationStatus(id, true);
    store.addNotification({
      content: function Data() {
        return (
          <>
            <NotificationSound />
            <div
              style={{
                background: `linear-gradient(180deg,${status === '0' ? '#139ff0 0,#007fed' : '#2df013 0,#43d04f'})`,
                color: 'white',
                borderRadius: '.5rem',
                padding: '10px 5px'
              }}
              onClick={() => {
                updateNotificationStatus(id, 2);
                navigate(Pathname.dashboard);
              }}
            >
              <span>{message}</span>
              <hr />
              <small style={{ fontSize: '12px' }}>This message will be dismissed in 5 seconds</small>
            </div>

          </>
        );
      },
      container: 'top-left',
      insert: 'top',
      dismiss: {
        duration: 5000,
      },
    });
  };
  const onLinkClick = (e) => {
    e.preventDefault();
    navigate(Pathname.getProfilePath());
    setdataOfCategory();
  };
  const spinerSmall = 'spinex';
  return (
    <>
      <ReactNotification styles={{ zIndex: 999999, width: '100%' }} />

      <header
        className={cn({
          [styles.glassCompactHeaderBG]: glassCompactHeaderBG,
          [styles.header]: true,
          [styles.noHeader]: noHeader,
          [styles.noRegularHeader]: noRegularHeader || isProfileHeader,
          [styles.pageScrolled]: pageHasScrolled,
          [styles.transparentCompactView]: transparentCompactHeader,
          [styles.headerTheme]: !location.pathname.includes('/profile')
            // && !location.pathname.includes('/access')
            // && location.pathname !== '/'
            // && location.pathname !== ''
            && !location.pathname.includes('/plan')
            && !location.pathname.includes('/sign-in')
            && !location.pathname.includes('/getting-started')
        })}
      >
        {
          (location.pathname.includes('/access') || location.pathname === '/' || location.pathname === '') ? (
            <div className={styles.semiOvalWrap}>
              <div className={styles.box} />
            </div>
          ) : null
        }
        <div className={styles.headerContent}>
          <Link className={styles.regularView} to={Pathname.access}>
            <Logo />
          </Link>
          {hideMenu
            ? <>&nbsp;</>
            : (
              <button className={cn({ [styles.menuButton]: true, [styles.compactView]: true })} onClick={onMenuClick}>
                <MenuIcon className={styles.menuIcon} />
              </button>
            )}

          <div className={styles.options}>
            {(!location.search.includes('?cancel-subscription')) && (
              <>
                {userDetails.totalContentUploaded > 0 ? (
                  <>
                    {(location.pathname !== '/access' && location.pathname !== '/profile') ? (
                    <Link
                      style={{ margin: 'auto 1rem ' }}
                      className={cn({
                        [styles.profileMenuLink]: true,
                        [styles.uploadText]: true,
                        [styles.fontBold]: true,
                        [styles.myProjectorLink]: true,
                        [styles.alignIcon]: true,
                      })}
                      to={Pathname.getProfilePath()}
                      onClick={onLinkClick}
                    >
                      {headerText?.viewProjector || 'View my Projector'}
                    </Link>
                    )
                    : (
                    <Link
                      style={{ margin: 'auto 1rem ' }}
                      className={cn({
                        [styles.profileMenuLink]: true,
                        [styles.uploadText]: true,
                        [styles.fontBold]: true,
                        [styles.myProjectorLink]: true,
                        [styles.alignIcon]: true,
                      })}
                      to={Pathname.dashboard}
                    >
                      {headerText?.dropDownData?.contentDashboard || 'Content Dashboard'}
                    </Link>
                    )}
                  </>
                ) : null}
                {/* {userDetails.totalVideoUploaded > 0
                  ? (
                    <Link
                      style={{ margin: 'auto 1rem ' }}
                      className={`${styles.profileMenuLink} ${styles.uploadText} ${styles.fontBold} ${styles.myProjectorLink}`}
                      to={Pathname.getProfilePath()}
                    >
                      View my projector
                    </Link>
                  )
                  : null} */}
                {
                  !isInActiveUser ? (
                    <button
                    className={cn({
                      [styles.uploadButton]: true,
                      [styles.regularView]: true,
                      [styles.uploadButtonHover]: !(draftcontent?.length > 0 || uploaderLoader === true || activeUploads?.length > 0), // Conditionally apply hover
                      // [styles.disableduploadButton]: draftcontent?.length > 0 || uploaderLoader === true || activeUploads?.length > 0,
                    })}
                    // onClick={() => {
                    //   if (!(activeUploads?.length > 0)) {
                    //     revealUploadModal();
                    //   }
                    // }}
                    onClick={revealUploadModal}
                      // disabled={draftcontent?.length > 0 || uploaderLoader === true || activeUploads?.length > 0}
                    >
                      <span className={styles.uploadText} style={{ marginRight: uploaderLoader === true ? '10px' : '' }}>{headerText?.upload || 'Upload'}</span>
                      {uploaderLoader === true
                      ? (<Spinner spinex={spinerSmall} />)
                      : (<div className={styles.uploadIcon}>+</div>
                      )}
                    </button>
                  ) : null
                }
                <Notifications
                  handlePushNotification={handlePushNotification}
                  updateNotificationStatus={updateNotificationStatus}
                  getUserConnections={getUserConnections}
                  clearing={clearing}
                  setClearing={setClearing}
                />
                {/* <VideoUploadProgress /> */}
                <button
                  className={cn({ [styles.uploadButton]: true, [styles.compactView]: true })}
                  onClick={revealUploadModal}
                >
                  <VideoUploadIcon className={styles.uploadIcon} />
                </button>

              </>
            )}

            <button
              aria-controls="profile-menu"
              className={cn({ [styles.profileButton]: true, [styles.regularView]: true })}
              onClick={revealProfileMenu(null)}
            >

              {
                userDetails?.dpURL ? (
                  <img
                    onError={(e) => {
                      e.target.src = AlterImage;
                    }}
                    // draggable={false}
                    className={`${styles.profileMenuCurrentProfileDetailsIcon} ${styles.border}`}
                    src={userDetails?.dpURL || AlterImage}
                  />
                )
                  : <PersonIcon className={`${styles.profileMenuCurrentProfileDetailsIcon} ${styles.border2}`} />
              }
            </button>
            <button
              className={cn({ [styles.profileButton]: true, [styles.compactView]: true })}
              onClick={revealProfileMenu(null)}
            >
              {
                userDetails?.dpURL ? (
                  <img
                    className={styles.profileMenuCurrentProfileDetailsIcon}
                    src={userDetails?.dpURL || AlterImage}
                    onError={(e) => {
                      e.target.src = AlterImage;
                    }}
                  />
                )
                  : <PersonIcon className={styles.profileIcon} />
              }
            </button>
          </div>
        </div>
      </header>
      <div
        className={cn({
          [styles.headerFill]: true,
          [styles.noHeader]: noHeader || videoBanner,
          [styles.noRegularHeader]: noRegularHeader || isProfileHeader,
        })}
      />
      {
        videoBanner && layoutData?.length ? (
          <VideoBanner
            layoutData={layoutData}
            profileDetails={profileDetails}
            revealProfileMenu={revealProfileMenu}
            storageFullCondition={storageFullCondition}
            setStorageFull={setStorageFull}
            revealUploadModal={revealUploadModal}
          />
        ) : null
      }
      <header className={cn({ [styles.profileHeader]: true, [styles.hidden]: !isProfileHeader })}>

        <div className={styles.profileHeaderContent}>

          <div className={styles.leftContent} style={{ flex: '1' }}>
            {
              !location.pathname.includes('/profile')
                ? (
                  // <IconButton>
                  // </IconButton>
                  <div
                    // style={{
                    //   // border: 'solid white',
                    //   display: 'flex',
                    //   justifyContent: 'center',
                    //   alignItems: 'center',
                    //   // borderRadius: '9999999999999px',
                    //   padding: '.2rem',
                    //   marginRight: '.5rem'
                    // }}
                    className={`${!location.pathname.includes('/video/') && styles.backButton}`}
                  >

                    <img
                      src={ArrowBackIos || AlterImage}
                      style={{ color: 'white', cursor: 'pointer', marginRight: '0' }}
                      fontSize="large"
                      onClick={() => navigate(-1)}
                      className={styles.profileHeaderIconButtonIcon}
                      onError={(e) => {
                        e.target.src = AlterImage;
                      }}
                    />
                  </div>
                ) : null
            }
            {!location.pathname.includes('/video') ? (
              <>
                <button
                  aria-controls="profile-menu"
                  className={cn({
                    [styles.profileButton]: true,
                    [styles.regularView]: true,
                    [styles.profileHeaderIconButton]: true,
                  })}
                  onClick={revealProfileMenu('projectors')}
                >
                  {
                    profileDetails.image ? (
                      <img
                        src={profileDetails?.image || AlterImage}
                        className={cn({
                          [styles.profileMenuCurrentProfileDetailsIcon]: true,
                          [styles.border]: true,
                          [styles.profileIconPosition]: true,
                          [styles.profileMouseOver]: isMouseOver === 'profile',
                        })}
                        onMouseEnter={() => { setMouseOver('profile'); }}
                        onMouseLeave={() => { setMouseOver(null); }}
                        onError={(e) => {
                          e.target.src = AlterImage;
                        }}
                      />
                    ) : (
                      <PersonIcon
                        className={cn({
                          [styles.profileMenuCurrentProfileDetailsIcon]: true,
                          [styles.border2]: true,
                          [styles.profileIconPosition]: true,
                          [styles.profileMouseOver]: isMouseOver === 'profile',
                        })}
                        onMouseEnter={() => { setMouseOver('profile'); }}
                        onMouseLeave={() => { setMouseOver(null); }}
                      />
                    )
                  }
                  <div
                    className={`${styles.profileHeaderIconButtonText} ${styles.namePosition} ${isMouseOver === 'profile' ? styles.profileMouseOver : ''}`}
                    onMouseEnter={() => { setMouseOver('profile'); }}
                    onMouseLeave={() => { setMouseOver(null); }}
                  >
                    {profileDetails.firstname?.substring(0, 10) || profileDetails.email?.split('@')[0]?.substring(0, 10)}
                  </div>
                </button>
                <HeaderIconButton
                  iconType="home"
                  setMouseOver={setMouseOver}
                  isMouseOver={isMouseOver}
                  icon={HomeIcon}
                  text={headerText?.home || 'Home'}
                  link={profileID ? Pathname.getFriendProfilePath(profileID) : Pathname.getProfilePath()}
                  selectedTabClass={selectedTab === 'home'}
                />
                <HeaderIconButton
                  iconType="search"
                  icon={SearchIcon}
                  text={headerText?.search || 'Search'}
                  link={Pathname.getFriendSearch(profileID)}
                  setMouseOver={setMouseOver}
                  isMouseOver={isMouseOver}
                  selectedTabClass={selectedTab === 'search'}
                />
                <HeaderIconButton
                  iconType="watchlist"
                  icon={WatchlistIcon}
                  text={headerText?.watchlist || 'Watchlist'}
                  link={Pathname.getFriendWatchList(profileID)}
                  setMouseOver={setMouseOver}
                  isMouseOver={isMouseOver}
                  selectedTabClass={selectedTab === 'watchlist'}
                />
              </>
            )
              : null}
            {/* {(userName && userProfileLink) && (
              <HeaderIconButton
                icon={ProfileIcon}
                text={`${(userName?.length > 16) ? `${userName?.substring(0, 16)}...` : userName}'s Page`}
                link={userProfileLink}
              />
            )} */}
          </div>
          <div className={styles.rightContent}>
            {!location.pathname.includes('/video') ? (
              <>
                {
                  !isInActiveUser ? (
                    <HeaderIconButton
                      // disabled={activeUploads?.length > 0}
                      text={headerText?.upload || 'UPLOAD'}
                      uploaderLoader={uploaderLoader}
                      // activeUploads={activeUploads?.length > 0}
                      // onClick={() => {
                      //   if (!(activeUploads?.length > 0)) {
                      //     revealUploadModal();
                      //   } else if (activeUploads?.length > 0) {
                      //     setAppSnackbar({ isVisible: true, type: 'error', message: 'You have ongoing uploads, please add new files after they\'re complete' });
                      //   }
                      // }}
                      onClick={() => { revealUploadModal(); }}
                      link="#"
                      setMouseOver={setMouseOver}
                      isMouseOver={isMouseOver}
                      iconType="upload"
                    />
                  ) : null
                }
                <HeaderIconButton
                  aria-controls="profile-menu"
                  text={headerText?.account || 'ACCOUNT'}
                  onClick={revealProfileMenu(null)}
                  link="#"
                  setMouseOver={setMouseOver}
                  isMouseOver={isMouseOver}
                  iconType="account"
                />
              </>
            ) : null}
          </div>
        </div>
      </header>

      <Menu
        anchorEl={profileMenuAnchorEl}
        id="profile-menu"
        MenuListProps={{ className: styles.profileMenu }}
        onClose={hideProfileMenu}
        open={profileMenuIsVisible}
        PopoverClasses={{ paper: styles.profileMenuContainer }}
      >
        {
          [
            <>
              <div className={cn({ [styles.profileMenuCurrentProfileDetails]: menuItem !== 'projectors' })}>
                {
                  menuItem === 'projectors' ? (
                    <div className={styles.profileMenuCurrentProfileDetailsText}>
                      {profileName}
                    </div>
                  ) : (
                    <>
                      <div className={styles.profileMenuCurrentProfileDetailsText}>
                        {userDetails.firstname?.substring(0, 10) || userDetails.email?.split('@')[0]?.substring(0, 10)}
                      </div>
                      {
                        userDetails?.dpURL !== null ? (
                          <img
                            className={`${styles.profileMenuCurrentProfileDetailsIcon} ${styles.border}`}
                            src={userDetails?.dpURL || AlterImage}
                            onError={(e) => {
                              e.target.src = AlterImage;
                            }}
                          />
                        ) : (
                          <ProfileIcon className={`${styles.profileMenuCurrentProfileDetailsIcon} ${styles.border2}`} />
                        )
                      }
                    </>
                  )
                }
              </div>

              {
                menuItem !== 'projectors' ? <hr className={styles.profileMenuDivider} /> : null
              }
              {
                menuItem === 'account' ? (
                  <div className={styles.profileMenuLinksContainer}>
                    {rightLinks.map(({ name, link, onClick }, idx) => (
                      <Link to={link} key={idx} onClick={onClick} className={styles.profileMenuLink}>{name}</Link>
                    ))}
                  </div>
                ) : menuItem === 'projectors' ? (
                  <>
                    <Link className={styles.profileMenuProfile} to={Pathname.access}>
                      <DehazeIcon className={styles.iconColor} />
                      <div className={cn({ [styles.profileMenuProfileName]: true, [styles.allProjectorAlign]: true })}>
                        {headerText?.allProjector || 'All Projectors'}
                      </div>
                    </Link>
                    <div className={styles.profileMenuProfilesContainer}>
                      {userList.map(({ firstname, lastname, email, id, image, type }, idx) => (
                        <ProfileMenuProfileButton
                          email={email}
                          Icon={PersonIcon}
                          key={idx}
                          setProfileDetails={setProfileDetails}
                          link={Pathname.getFriendProfilePath(id)}
                          name={`${firstname} ${lastname}`.trim()}
                          firstname={firstname === 'Your' ? userDetails?.firstname : firstname}
                          image={image}
                          isPerson
                          onClick={(e) => { updateUserDetails(firstname, lastname, email, id, image, type); }}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    {/* {
                location.pathname.includes('/access') ? (
                  <div className={styles.profileMenuProfilesContainer}>
                    {profiles.map(({ firstname, lastname, email, id, image }, idx) => (
                      <ProfileMenuProfileButton
                        email={email}
                        Icon={PersonIcon}
                        key={idx}
                        link={Pathname.getFriendProfilePath(id)}
                        name={`${firstname} ${lastname}`.trim()}
                        image={image}
                        isPerson
                      />
                    ))}
                    <ProfileMenuProfileButton Icon={AddIcon} link="#" name={headerText?.dropDown?.requestAccess || 'Request access'} onClick={revealAccessRequestModal} />
                  </div>
                ) : null
              } */}
<div className={styles.profileMenuLinksContainer}>
  {links.map(({ name, link, onClick, type, typeOf }, idx) => {
    if (!location.pathname.includes(link)) {
      if (type === 'help') {
        return (
          <a href={link} key={idx} className={styles.profileMenuLink} target="_blank" rel="noreferrer">
            {name}
          </a>
        );
      }
      if (getAccessChecker?.success !== true && typeOf === 'dropdown') {
        return (
          // <BasicAccordion name={name} key={idx} />
          <Link to={link} key={idx} onClick={onClick} className={styles.profileMenuLink}>
          {name}
          </Link>
        );
      }
      if (getAccessChecker?.success === true && typeOf === 'dropdown') {
        return null;
      }
      return (
        <Link to={link} key={idx} onClick={onClick} className={styles.profileMenuLink}>
          {name}
        </Link>
      );
    }
    return null;
  })}
</div>

                  </>
                )
              }
            </>
          ]
        }
      </Menu>

      <Drawer anchor="right" open={profileMenuIsVisible} className={styles.profileBar} onClose={hideProfileMenu}>
        <div className={styles.profileBarContainer}>
          <div className={styles.profileBarContent}>
            <div className={styles.profileBarProfilesContainer}>
              <div>
                <div className={styles.profileBarProfilesContent}>
                  {profiles.map(({ firstname, lastname, email, id, image, type }, idx) => (
                    <ProfileDrawerProfileButton
                          email={email}
                          Icon={ProfileIcon}
                          key={idx}
                          link={Pathname.getFriendProfilePath(id)}
                          name={`${firstname} ${lastname}`.trim()}
                          image={image}
                          isPerson
                          onClick={(e) => { updateUserDetails(firstname, lastname, email, id, image, type); }}
                    />
                  ))}
                  <ProfileDrawerProfileButton
                    Icon={AddIcon}
                    link="#"
                    name={headerText?.dropDown?.requestAccess || 'Request access'}
                    onClick={revealAccessRequestModal}
                  />
                </div>
              </div>
            </div>
            <div className={styles.profileBarLinksContainer}>
              {links.map(({ name, link, onClick }, idx) => (
                <Link to={link} key={idx} onClick={onClick} className={styles.profileBarLink}>{name}</Link>
              ))}
            </div>
          </div>
        </div>
      </Drawer>

      <UploadModal isVisible={uploadModalIsVisible} onHide={hideUploadModal} />
      <VideoPopupModal isVisible={popUpModalVisible} onHide={hidepopModal} />
      {
        showProgressBox ? <ProgressBox /> : null
      }
      <ShareScreen
        actionButtonLoadingText={`${commonText?.requestingAccess || 'Requesting Access'}...`}
        actionButtonText={commonText?.requestAccess || 'Request Access'}
        apiEndpoint={endpoints.searchCreatorByEmail}
        errorText={commonText?.somethingWentWrong || 'Oops! Something went wrong'}
        inputPlaceholder={commonText?.searchEmail || 'Search by email'}
        isVisible={accessRequestModalIsVisible}
        multipleSelect
        notes={commonText?.enterEmail || 'Enter an email to search'}
        noUsersFoundText={commonText?.noUser || 'No users found with this email'}
        onContinue={requestProfileAccess}
        onHide={hideAccessRequestModal}
        successText={commonText?.requestSent || 'Request sent'}
        title={commonText?.requestAccessTitle || 'Request Access to View'}
      />
      <ShareScreen
        actionButtonLoadingText={`${commonText?.grantingAccess || 'Granting Access'}...`}
        actionButtonText={commonText?.add || 'Add'}
        apiEndpoint={endpoints.searchUserToInvite}
        errorText={commonText?.somethingWentWrong || 'Oops! Something went wrong'}
        inputPlaceholder={commonText?.searchEmail || 'Search by email'}
        isVisible={accessGrantModalIsVisible}
        multipleSelect
        notes={commonText?.enterEmail || 'Enter an email to search'}
        noUsersFoundText={commonText?.noUser || 'No users found with this email'}
        onContinue={grantProfileAccess}
        onHide={hideAccessGrantModal}
        successText={commonText?.accessGranted || 'Access Granted'}
        title={commonText?.grantAccessTitle || 'Grant Access'}
        grandAccess
      />
      <ShareScreen
        actionButtonLoadingText={`${commonText?.invitingFriend || 'Inviting friend'}...`}
        actionButtonText={commonText?.invite || 'Invite'}
        apiEndpoint={endpoints.searchUserInSystem}
        errorText={errorMsg || commonText?.somethingWentWrong || 'Oops! Something went wrong'}
        inputPlaceholder={commonText?.enterEmailOnly || 'Enter email'}
        isVisible={referFriendModalIsVisible}
        multipleSelect
        notes={commonText?.enterFriendEmail || 'Enter your friends email address'}
        noUsersFoundText={commonText?.noUser || 'No users found with this email'}
        onContinue={sendInvitation}
        onHide={hideInviteFriendModal}
        successText={commonText?.invitedFriend || 'Invited Friend'}
        title={commonText?.referFriendTitle || 'Refer A Friend'}
        subTitle={commonText?.referFriendSubTitle || 'You get two free months on a paid plan and they get a free month'}
        grandAccess
      />
      <CreateGroup />
      {/* {
        isStorageFull && (
          <InfoModal
            isVisible={isStorageFull}
            title={processingInfo ? 'Video being processed' : contentDashboard?.timeToUpgradePlan || 'Time To Upgrade Plans'}
            onHide={() => { setStorageFull(false); }}
            continueButton={processingInfo ? 'Goto Contents' : contentDashboard?.upgrade || 'Upgrade'}
            content={processingInfo ? 'Your video will show up in your contents after it is finished processing.' : contentDashboard?.storageExceeded || 'You have exceeded your storage or viewer limit. Click upgrade to see available options!'}
            pathName={processingInfo ? Pathname.yourVideos : Pathname.plans}
            processingInfo
          />
        )
      } */}
       {mangerFunctionData === true
      && <MessageComponent setmangerFunctionData={setmangerFunctionData} message={messaged || 'Invalid'} />}
    </>
  );
};
