/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CircularProgress as MuiCircularProgress, withStyles, makeStyles, Grid, IconButton } from '@material-ui/core';
import { Zoom } from 'react-slideshow-image';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import { ReactComponent as FullScreen } from 'Assets/full-screen.svg';
import { ReactComponent as NormalScreen } from 'Assets/normal-screen.svg';
import screenfull from 'screenfull';
import { dataFetcher, endpoints } from 'Api';
import { AppContext } from 'Context';
import useSWR from 'swr';
import styles from './index.module.css';

const CircularProgress = withStyles({
  circle: {
    color: '#fff !important'
  }
})(MuiCircularProgress);

export function AlbumPlayer() {
  // eslint-disable-next-line no-script-url
  window.location.href = 'javascript: $zopim.livechat.button.hide()';
  const defaultImage = 'http://lorempixel.com/1000/600/nature/1/';
  const navigate = useNavigate();
  const duration = 5000;
  const { setAppSnackbar } = useContext(AppContext);
  const { albumID, photoId } = useParams();
  const [albumDetails, setAlbumDetails] = useState([]);
  const [photoArraySet, setPhotoArraySet] = useState([]);
  const trueValue = true;
  const playerRef = useRef(null);
  const [title, setTitle] = useState('');
  let intervalIsGoingOn = false;
  const [showIcons, setIcons] = useState(false);
  const [transitionType, setTransitionType] = useState('fadeIn');
  const [transitionTime, setTransitionTime] = useState(20);
  const [screenSize, setScreenSize] = useState(false);
  const playerContainerRef = useRef(null);
  const [isTransition, setTransition] = useState(true);
  const [start, setStart] = useState(0);
  const [isPlay, setPlay] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(undefined);
  const [addOnSlice, setAddOnSlice] = useState(20);
  let intervalForTransition = false;
  const useStyles = makeStyles({
    playerWrapper: {
      width: '100%',
      position: 'relative',
      height: '100vh',
      background: 'black',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    controlesWrapper: {
      width: '100%',
      height: '100vh',
      position: 'absolute',
      overflow: 'hidden',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: 'black',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      zIndex: 1,
    },
    titleTrim: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '50ch',
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 25
    },
    fadeIn: {
      '& .image-gallery-content': {
        '& .image-gallery-slide-wrapper': {
          '& .image-gallery-swipe': {
            '& .image-gallery-slides': {
              '& .image-gallery-slide.center': {
                animation: '$fadein 1s',
              }
            }
          }
        }
      },
    },
    spinner: {
      '& .image-gallery-content': {
        '& .image-gallery-slide-wrapper': {
          '& .image-gallery-swipe': {
            '& .image-gallery-slides': {
              '& .image-gallery-slide.center': {
                animation: '$spinner-rotate 1s cubic-bezier(.5, 0, 1, 1), $blinker 1s cubic-bezier(.5, 0, 1, 1)'
              }
            }
          }
        }
      },
    },
    moveDown: {
      '& .image-gallery-content': {
        '& .image-gallery-slide-wrapper': {
          '& .image-gallery-swipe': {
            '& .image-gallery-slides': {
              '& .image-gallery-slide.center': {
                transition: 'opacity 1s ease-out',
                animation: '$moveSlideshowDown 1s linear',
                animationIterationCount: 1,
              }
            }
          }
        }
      },
    },
    moveUp: {
      '& .image-gallery-content': {
        '& .image-gallery-slide-wrapper': {
          '& .image-gallery-swipe': {
            '& .image-gallery-slides': {
              '& .image-gallery-slide.center': {
                transition: 'opacity 1s ease-out',
                animation: '$moveSlideshowUp 1s linear'
              }
            }
          }
        }
      },
    },
    moveRightToLeft: {
      '& .image-gallery-content': {
        '& .image-gallery-slide-wrapper': {
          '& .image-gallery-swipe': {
            '& .image-gallery-slides': {
              '& .image-gallery-slide.center': {
                transition: 'opacity 1s ease-out',
                animation: '$moveSlideshowRight 1s linear'
              }
            }
          }
        }
      },
    },
    moveLeftToRight: {
      '& .image-gallery-content': {
        '& .image-gallery-slide-wrapper': {
          '& .image-gallery-swipe': {
            '& .image-gallery-slides': {
              '& .image-gallery-slide.center': {
                transition: 'opacity 1s ease-out',
                animation: '$moveSlideshowLeft 1s linear reverse'
              }
            }
          }
        }
      },
    },
    slideIn: {
      '& .image-gallery-content': {
        '& .image-gallery-slide-wrapper': {
          '& .image-gallery-swipe': {
            '& .image-gallery-slides': {
              '& .image-gallery-slide.center': {
                animation: '$slide-in 1s forwards'
              }
            }
          }
        }
      },
    },
    '@keyframes slide-in': {
      '100%': { transform: 'translateX(0%)' }
    },
    '@keyframes moveSlideshowLeft': {
      '100%': {
        transform: 'translateX(-50%)',
      }
    },
    '@keyframes moveSlideshowRight': {
      '0%': {
        transform: 'translateX(100%)',
      }
    },
    '@keyframes moveSlideshowUp': {
      '0%': {
        transform: 'translateY(100%)',
      }
    },
    '@keyframes moveSlideshowDown': {
      '0%': {
        transform: 'translateY(-100%)',
      }
    },
    '@keyframes blinker': {
      'from': { opacity: 1 },
      'to': { opacity: 0.3 }
    },
    '@keyframes spinner-rotate': {
      'from': { transform: 'rotate(0deg)' },
      'to': { transform: 'rotate(180deg)' }
    },
    '@keyframes fadein': {
      'from': { opacity: 0 },
      'to': { opacity: 1 }
    },
    controllIcons: {
      color: '#777',
      fontSize: 92,
      transform: 'scale(0.9)',
      '&:hover': {
        color: '#fff',
        transform: 'scale(1)',
      },
    },
  });

  const { isValidating: gettingAlbums, mutate: getAlbumDetails } = useSWR(
    [endpoints.getMyAlbumDetail, albumID],
    {
      fetcher: (url, album_id) => dataFetcher(url, { album_id }),
      onSuccess: ({ success, data, transition_speed, transition_type }) => {
        if (success) {
          setTransitionType(transition_type);
          setTransitionTime(parseInt(transition_speed, 10));
          const respData = data[0]?.photos;
          const index = respData?.findIndex(({ id }) => id === photoId);
          setTitle(data[0]?.title);
          let photoArray;
          if (index !== -1) {
            photoArray = respData?.filter(({ id, order_number }) => respData[index].order_number <= order_number);
            setPhotoArraySet(photoArray);
          }
        } else {
          setAppSnackbar({
            isVisible: true,
            type: 'error',
            message: 'Oops! Something went wrong',
          });
        }
      },
      onError: () => {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong',
        });
      },
    }
  );
  const updateAlbumDetails = () => {
    const slicedArray = photoArraySet?.slice(0, addOnSlice).map(({ photo_file, imagekit_url }) => ({
      'original': imagekit_url,
      'thumbnail': imagekit_url
    }));
    setAlbumDetails(slicedArray);
  };
useEffect(() => {
  updateAlbumDetails();
}, [photoArraySet, addOnSlice]);

  const handlePlayPause = () => {
    playerRef.current.togglePlay(!playerRef.current.state.isPlaying);
  };

  const handleRewind = () => {
    const totalImage = playerRef.current.props.items.length;
    playerRef.current.slideLeft((totalImage + (playerRef.current.state.currentIndex - 1)) % totalImage);
  };

  const handleFastForward = () => {
    const totalImage = playerRef.current.props.items.length;
    playerRef.current.slideRight((playerRef.current.state.currentIndex + 1) % totalImage);
  };

  const handleUserKeyPress = (event) => {
    const { key, keyCode } = event;
    if (keyCode === 32) {
      handlePlayPause();
    }
    if (keyCode === 37) { // left arrow key
      handleRewind();
    }
    if (keyCode === 39) { // right arrow key
      handleFastForward();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  });

  useEffect(() => {
    getAlbumDetails();
  }, []);

  const classes = useStyles();
  const zoomOutProperties = {
    duration,
    transitionDuration: 500,
    infinite: false,
    indicators: true,
    scale: 0.4,
    arrows: true
  };

  const handleChange = (event, newValue) => {
    newValue === albumDetails.length - 1 ? setTimeout(() => {
      navigate(-2);
    }, duration) : null;
  };

  const Slideshow = () => (
    <div className="slide-container">
      {
        gettingAlbums ? (<CircularProgress />) : (
          <Zoom {...zoomOutProperties} onChange={(e, newValue) => { handleChange(e, newValue); }}>
            {albumDetails?.map((each, index) => (
              <img key={index} style={{ width: '100%', height: '60vh' }} src={each} />
            ))}
          </Zoom>
        )
      }
    </div>
  );

  const showControllers = () => {
    if (!showIcons && !isTransition) {
      setIcons(true);
    }
    (() => {
      clearTimeout(intervalIsGoingOn);
      intervalIsGoingOn = setTimeout(() => {
        if (showIcons && !isTransition) {
          setIcons(false);
        }
      }, 20000000);
    })();
  };

  useEffect(() => {
    const canvas = document.getElementById('album-player');
    canvas.addEventListener('mousemove', (e) => {
      if (!isTransition) {
        showControllers();
      }
    });
    // hide icon after 2 sec after start the album play (without mouse move)
    (() => {
      clearTimeout(intervalIsGoingOn);
      intervalIsGoingOn = setTimeout(() => {
        if (showIcons && !isTransition) {
          setIcons(false);
        }
      }, 200000);
    })();
    // ['mousemove', 'click'].forEach((evt) => document.addEventListener(evt, showControllers, false));
  });

  const renderPlayPauseButton = ({ onClick, isPlaying }) => ((
    <button
      type="button"
      className="image-gallery-icon image-gallery-play-button"
      aria-label="Play or Pause Slideshow"
      onClick={onClick}
    />
  ));

  const onToggleFullScreen = () => {
    screenfull.toggle(playerContainerRef.current);
    setScreenSize(!screenSize);
  };

  const slideHandler = (currentIndex) => {
    setCurrentIndex(currentIndex);
    if (currentIndex === addOnSlice - 1) {
      if (currentIndex === photoArraySet?.length - 1) {
        setStart(0);
      } else {
        setAddOnSlice((prevAddOnSlice) => {
          const r = prevAddOnSlice + 10;
          setStart(prevAddOnSlice - 1);
          return r;
        });
      }
    }
    clearTimeout(intervalForTransition);
    intervalForTransition = setTimeout(() => {
      if (isTransition) {
        setTransition(false);
      }
    }, 1000);
  };

  const nextSlideHandler = (nextIndex) => {
    setIcons(false);
    if (!isTransition) {
      setTransition(true);
    }
  };

  const playPauseHandler = (currentIndex) => {
    setPlay(!isPlay);
  };

  const onImageLoadHandler = (event) => {
    (() => {
      clearTimeout(intervalForTransition);
      intervalForTransition = setTimeout(() => {
        if (currentIndex === undefined) {
          setTransition(false);
        }
      }, 1000);
    })();
  };

  return (
    <>
      {/* <div id="albumPlayer"> */}
      <div id="album-player" ref={playerContainerRef} style={{ pointerEvents: isTransition ? 'none' : 'auto' }} className={classes.controlesWrapper}>
        {/* <Slideshow /> */}
        {
          showIcons ? (
            <div className={styles.header}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
                style={{ padding: 16 }}
              >
                <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    aria-hidden="true"
                    className={styles.backButton}
                    onClick={() => { navigate(-1); }}
                  >
                    Back
                  </div>
                </Grid>
                <Grid item xs={10} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ color: '#fff' }} className={classes.titleTrim}>
                    {title}
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={onToggleFullScreen}
                    className={classes.controllIcons}
                  >
                    {
                      screenSize ? <NormalScreen style={{ width: '80%' }} className={styles.colorChangePlay} /> : <FullScreen style={{ width: '80%' }} className={styles.colorChangePlay} />
                    }
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          ) : null
        }
        <div>
          {
              gettingAlbums ? (<CircularProgress className={styles.circularProgress} />) : (
                <ImageGallery
                  // useTranslate3D={false}
                  ref={playerRef}
                  items={albumDetails}
                  // defaultImage={albumDetails[0]?.original}
                  showNav={showIcons}
                  showFullscreenButton={false}
                  showThumbnails={showIcons}
                  // lazyLoad={trueValue}
                  showPlayButton={showIcons}
                  additionalClass={isTransition && isPlay && !showIcons ? classes[transitionType] : null}
                  // swipingTransitionDuration={transitionTime * 1000}
                  slideInterval={transitionTime * 1000}
                  slideDuration={1}
                  onSlide={slideHandler}
                  onBeforeSlide={nextSlideHandler}
                  // renderPlayPauseButton={renderPlayPauseButton}
                  useBrowserFullscreen={false}
                  autoPlay={!gettingAlbums}
                  // loading="lazy"
                  onImageLoad={onImageLoadHandler}
                  swipeThreshold={10}
                  onPause={playPauseHandler}
                  onPlay={playPauseHandler}
                  startIndex={start}
                />
              )
            }
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
