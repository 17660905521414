import React from 'react';

const ThreeDotLoader = ({ colour, size }) => {
  // Define the size styling
  const dotStyle = {
    width: size,
    height: size, // Use 'height' with lowercase 'h'
  };

  return (
    <div className="loader" style={{ height: size && '10px' }}>
      <div style={{ ...dotStyle, backgroundColor: colour }} className="dot" />
      <div style={{ ...dotStyle, backgroundColor: colour }} className="dot" />
      <div style={{ ...dotStyle, backgroundColor: colour }} className="dot" />
    </div>
  );
};

export default ThreeDotLoader;
