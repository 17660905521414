import { useContext, useRef, useEffect, useMemo, useState } from 'react';
import { AppContext } from 'Context';
import useSWR from 'swr';
import Paper from '@mui/material/Paper';
import { axios, dataFetcher, endpoints, baseUrl } from 'Api';
import Table from '@mui/material/Table';
import { ref, get, getDatabase, off, onValue, update } from 'firebase/database';
import PlusButton from 'Assets/plus@2x.png';
import Add from 'Assets/add.png';
import Delete from 'Assets/trash.png';
import { toast } from 'react-toastify';
import Spinner from 'Components/Spinner';
import ListItemText from '@mui/material/ListItemText';
import { v4 as uuid } from 'uuid';
import AWS from 'aws-sdk';
import { addS3VideoDraftContent, addVideoDraftContent } from 'Api/apiFunctions';
import { saveUploadInfo, saveUploadDetails, saveTitle, saveDummy, removeDummy, removeTitle } from 'store/store';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { useSelector, useDispatch } from 'react-redux';
import noImageAvailable from 'Assets/noimage-available.png';
import Switch from '@mui/material/Switch';
import generatingThumbnail from 'Assets/generating-thumbnail.jpg';
import noPhotoAvailable from 'Assets/no-image-available.png';
import CloudUpl from 'Assets/cloud-upload.png';
import OutlinedInput from '@mui/material/OutlinedInput';
import TableHead from '@mui/material/TableHead';
import { useNavigate, useLocation } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import { ReactComponent as CloseIcon } from 'Assets/close.svg';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TableRow from '@mui/material/TableRow';
import { Checkbox as MuiCheckbox, withStyles, Typography } from '@material-ui/core';
import { Check as CheckIcon, Close } from '@material-ui/icons'; // Import the CheckIcon
import { Radio } from './upload-modal/components/Material-reDesign';
import { Checkbox, StyledCheckbox } from '../Pages/private/videos/components/CustomDesigns';
import { GroupSelector } from './group-selector/index';
import { awsConfig, generateS3Url, getBucketName, getBucketNamePath } from '../Api/awsConfig';
import styles from './Listing.module.css';
import { Pathname } from '../Routes';
import DeleteIcon from '../Assets/delete.png';
import SpinnerImage from '../Assets/Spinner-3.gif';
import ThreeDotLoader from './ThreeDotLoader';

export default function ListingModal({ buttonText, setButtonText, id, open, key, videoFiles, setVideoFiles, setOpen, setEditLoader }) {
  // const navigate = useNavigate();
  const { setAppSnackbar, s3Data, firebaseDatabase, setVideoUploadedParts, langText, userDetails,
    setUploadContainer, setIamUploading, setdisableDrag, setdidIOpenModal, subscriptionDetails,
    autoSignIn, setSelectedFiles, uploadQueue, setUploadQueue, activeUploads, setActiveUploads, MAX_VIDEOS, uploadProcessing,
    setUploadProcessing, setNavigateOnceClicked, selectedItemsInBulk, setSelectedItemsInBulk, videoThumbnails, setVideoThumbnails, processUploadQueue, videoThumbnails64, setVideoThumbnails64 } = useContext(AppContext);
    const spinerSmall = 'spinex';
  const uploadPercentages = useSelector((state) => state.upload);
  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  };

  const defaultVideoDetails = {
    name: undefined,
    duration: undefined,
    size: undefined,
  };
  const test = useSelector((state) => state.titleBulk);
const dispatch = useDispatch();
const updateTitle = (draft_id, data, status = 1) => {
  if (status === 1) {
    dispatch(saveTitle({ [draft_id]: data }));
  } else {
    dispatch(removeTitle(draft_id));
  }
};
  const getToken = () => localStorage.getItem('token');
  const videoRef = useRef(null);
  const databaseRef = ref(firebaseDatabase, 'status_from_sns');
  const userId = userDetails?.isSubscription?.user_id;
  const [t, setT] = useState(false);
  const [videoDetails, setVideoDetails] = useState(defaultVideoDetails);
  const [videoGroup, setVideoGroup] = useState([]);
  const [videoVisibility, setVideoVisibility] = useState(null);
  const [options, setOptions] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [thumbnailGenerationStatus, setThumbnailGenerationStatus] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isItDelete, setisItDelete] = useState(true);
  const [thumbnailMetadata, setThumbnailMetadata] = useState({});
  const [title, setTitle] = useState('');
  const [addEditCategory, setAddEditCategory] = useState(false);
  const [isThumbnailChanged, setIsThumbnailChanged] = useState({});
  const [selectedThumbnail, setSelectedThumbnail] = useState({});
const [addingNewCategory, setAddingNewCategory] = useState({});
  // const [videoThumbnails64, setVideoThumbnails64] = useState({});
  const [droper, setDroper] = useState(false);
  const handleCancel = (e, i) => {
    setTitle('');
    // setAddingNewCategory((prevState) => ({
    //   ...prevState,
    //   [i]: false, // Set the specific index to false
    // }));
  };
  useEffect(() => {
    // Only update the selectedThumbnail for drafts that have not been manually changed
    const initialSelectedThumbnails = selectedItemsInBulk.reduce((acc, item) => {
      const { draftId } = item;
      if (isThumbnailChanged[draftId]) return acc;
      const firstThumbnail = videoThumbnails[draftId]?.[0]; // Get first thumbnail
      if (firstThumbnail) {
        acc[draftId] = firstThumbnail;
      }
      return acc;
    }, {});
    setSelectedThumbnail((prev) => ({
      ...prev,
      ...initialSelectedThumbnails,
    }));
  }, [selectedItemsInBulk, videoThumbnails, isThumbnailChanged]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  function formatFileSizeAccMb(sizeInBytes) {
    const megabyte = 1024 * 1024;
    const sizeInMB = sizeInBytes / megabyte;
    return sizeInMB.toFixed(2);
  }
  useEffect(() => {
    // eslint-disable-next-line
    videoFiles.forEach((file) => {
      // eslint-disable-next-line
      file.draftId = uuid();
    });
  }, [updateTrigger]);
  useEffect(() => {
    const statuses = Object.values(thumbnailGenerationStatus);
    const anyGenerating = statuses.some((status) => status === 'generating');
    if (anyGenerating && isItDelete) {
      setIsLoading(true);
    } else {
      const allSuccessOrFailed = statuses.every((status) => status === 'one generated' || status === 'success' || status === 'failed');
      if (allSuccessOrFailed) {
        setIsLoading(false);
      }
    }
  }, [thumbnailGenerationStatus]);
  useEffect(() => {
    const allItems = videoFiles.map((obj, i) => ({
      token: getToken(),
      group_ids: selectedItemsInBulk[i]?.group_ids,
      draftId: obj?.draftId,
      thumbnails: videoThumbnails[obj?.draftId],
      name: obj.name,
      description: selectedItemsInBulk[i]?.description,
      category: selectedItemsInBulk[i]?.category,
      categoryName: selectedItemsInBulk[i]?.categoryName,
      playlist: selectedItemsInBulk[i]?.playlist,
      visibility: selectedItemsInBulk[i]?.visibility ?? '2',
      size: formatFileSizeAccMb(obj.size)
    }));
    setSelectedItemsInBulk(allItems);
  }, [videoFiles, videoThumbnails]);

  const selectGroup = (id, index) => {
    setSelectedItemsInBulk((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index].group_ids = videoGroup[index]?.includes(id)
        ? videoGroup[index].filter((groupId) => groupId !== id)
        : [...(videoGroup[index] || []), id];
      return updatedItems;
    });
    // Update videoGroup to keep track of groups for each item individually
    setVideoGroup((prevVideoGroup) => {
      const updatedVideoGroup = [...prevVideoGroup];
      updatedVideoGroup[index] = updatedVideoGroup[index]?.includes(id)
        ? updatedVideoGroup[index].filter((groupId) => groupId !== id)
        : [...(updatedVideoGroup[index] || []), id];
      return updatedVideoGroup;
    });
  };
  const iteratorNum = [];
  const totalNum = [];
  let tempPercentages;
  const formDataJsonString = [];
  AWS.config.update({
    region: awsConfig.region,
    accessKeyId: awsConfig.accessKey,
    secretAccessKey: awsConfig.secretKey,
  });
  AWS.config.httpOptions.timeout = 0;
  const s3 = new AWS.S3();
  const { isValidating: gettingPlaylists, mutate: getPlaylists } = useSWR(endpoints.getMyPlaylist, {
    onSuccess: ({ success, data }) => {
      if (success) {
        setOptions(data?.map(({ id, title }) => ({ name: title, value: id })));
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while fetching you playlists' });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while fetching you playlists' });
    },
  });
  const [categrory, setCategrory] = useState(undefined);
  const fetchCategories = async () => {
    try {
      const token = getToken(); // Get the token
      const response = await axios.post(
        `${baseUrl}/${endpoints.getMyCategories}`,
        {}, // Empty body for the POST request
        {
          headers: {
            Authorization: `Bearer ${token}`, // Token in Authorization header
          },
        }
      );
      // Check if the response is successful
      if (response?.data?.success) {
        setCategrory(response?.data?.data?.map(({ title: name, id: value }) => ({ name, value })));
        // eslint-disable-next-line
      } else {
        console.error('Failed to fetch categories:', response.data.message);
        return [];
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      return [];
    }
  };
  useEffect(() => {
    setEditLoader(false);
    getPlaylists();
    fetchCategories();
    setdisableDrag(false);
    setButtonText('Save to Draft');
    setIsLoading(false);
    setisItDelete(true);
    setThumbnailGenerationStatus({});
    setSelectedItemsInBulk([]);
    // setdidIOpenModal(true);
    return () => {
      // setdisableDrag(true);
      setdidIOpenModal(false);
    };
  }, []);

  const updateUploadDetails = (draft_id, data) => {
    dispatch(saveUploadDetails({ [draft_id]: data }));
  };

const updateItem = (percentage, draft_id, filename, uploadPercentages, updateUploadPercentages) => {
  const percent = parseInt((percentage / totalNum[draft_id]) * 100, 10);
  const trimmedFilename = filename.length > 26 ? `${filename.substring(0, 26)}...` : filename;
    const newPercentages = { ...uploadPercentages, [draft_id]: percent };
    tempPercentages = newPercentages;
    updateUploadPercentages(draft_id, percent);
    toast.update(draft_id, { render: `${trimmedFilename} ${percent}%`, autoClose: false });
};
const closeToast = (draft_id, message, type) => {
  toast.update(draft_id, { closeButton: false, render: message, type: type === 'success' ? toast.TYPE.SUCCESS : toast.TYPE.ERROR, autoClose: 3000 });
  setUploadContainer((prevUploadContainer) => prevUploadContainer.filter((id) => id !== draft_id));
};
async function uploadPart(bucket, key, partNumber, partBody, uploadId, setVideoUploadedParts, filename, draft_id, uploadPercentages, updateUploadPercentages, partSize, startTime, fileSize) {
  const params = {
    Bucket: bucket,
    Key: key,
    PartNumber: partNumber,
    UploadId: uploadId,
    Body: partBody,
  };
  return s3.uploadPart(params)
    .promise()
    .then((data) => {
      const currentTime = new Date().getTime();
      const elapsedSize = (partNumber * partSize) / 1024 / 1024;
      const elapsedTime = (currentTime - startTime) / 1000;
      const remainingSize = (fileSize / 1024 / 1024) - elapsedSize;
      updateUploadDetails(draft_id, { elapsed_size: elapsedSize, elapsed_time: elapsedTime, remaining_size: remainingSize > 0 ? remainingSize : 0 });
      iteratorNum[draft_id] += 1;
      updateItem(iteratorNum[draft_id], draft_id, filename, uploadPercentages, updateUploadPercentages);
      setVideoUploadedParts((prevState = []) => [...prevState, partNumber]);
      return data;
    })
    .catch((error) => {
      console.error(`AWS Multipart Upload : Error uploading part ${partNumber}:`, error);
      throw error; // Rethrow the error to be handled by the caller
    });
}

async function sendVideoDataToServer(theGreatPayload, draft_id) {
  const apiUrl = `${baseUrl}/V6/addVideo`;
  try {
    const response = await axios.post(apiUrl, theGreatPayload);
    closeToast(draft_id, 'Uploading completed', 'success');
  } catch (error) {
    console.error('Error sending video data:', error);
  }
}
async function completeMultipartUpload(bucket, key, parts, uploadId, file, index) {
  const params = {
    Bucket: bucket,
    Key: key,
    MultipartUpload: {
      Parts: parts,
    },
    UploadId: uploadId,
  };
  try {
    const response = await s3.completeMultipartUpload(params).promise();
    let videoDuration = 0;
    let videoHeightI = 0;
    let videoWidthI = 0;
    try {
      const videoElement = document.createElement('video');
      videoElement.src = URL.createObjectURL(file);
      await videoElement?.load();
      videoElement.muted = true;
  // Wait for the metadata to load to access duration, height, and width
  await new Promise((resolve, reject) => {
    videoElement.addEventListener('loadedmetadata', () => {
      videoDuration = videoElement.duration;
      videoHeightI = videoElement.videoHeight;
      videoWidthI = videoElement.videoWidth;
      resolve();
    });
    videoElement.addEventListener('error', reject);
  });
  try {
    await videoElement.play();
  } catch (playError) {
    console.warn('Video play was prevented:', playError);
  }
      videoElement?.pause();
    } catch (e) {
      console.error('Error in getting width and height:', e);
    }
    const formData = new FormData();
    const s3Url = generateS3Url();
    formData.append('token', getToken());
    formData.append('draft_id', file?.draftId);
    videoThumbnails[index] && formData.append('thumbnail', videoThumbnails[index][0] ?? videoThumbnails[index]);
    if (videoThumbnails[index] && Array.isArray(videoThumbnails[index])) {
      videoThumbnails[index].forEach((thumbnail) => {
        formData.append('thumbnails[]', thumbnail);
      });
    }
    formData.append('title', test[file?.draftId]);
    formData.append('video_duration', videoDuration);
    formData.append('videoOreintation', videoHeightI < videoWidthI ? 1 : 2);
    formData.append('videoHeight', videoHeightI);
    formData.append('videoWidth', videoWidthI);
    // formData.append('description', selectedItemsInBulk[index].description);
    selectedItemsInBulk[index].category ? formData.append('category_id', selectedItemsInBulk[index].category) : null;
    formData.append('playlist_ids', selectedItemsInBulk[index]?.playlist?.map((item) => item?.value));
    formData.append('visibility', selectedItemsInBulk[index].visibility);
    formData.append('group_ids', selectedItemsInBulk[index]?.group_ids);
    formData.append('s3_url', `${s3Url}${getBucketNamePath(userId)}/${key}`);
    formData.append('s3_image_bucket_url', s3Url);
    formData.append('s3_status', 'Completed');
    formData.append('bucket_folder_path', `${getBucketNamePath(userId)}/${key}`);
    formData.append('video_size', formatFileSizeAccMb(file.size));
    closeToast(file?.draftId, 'Uploading completed', 'success');
    const formDataToObject = (formData) => {
      const object = {};
      formData.forEach((value, key) => {
        object[key] = value instanceof File ? value.name : value; // Show file name for File objects
      });
      return object;
    };
    console.log('FormData as Object:', formDataToObject(formData));

    sendVideoDataToServer(formData, file?.draftId);
    if (subscriptionDetails?.current_plan === 'Free') {
      autoSignIn();
    }
    // if (uploadQueue?.length <= 1) {
    //   setOpen();
    // }
    return response;
  } catch (error) {
    console.error('Error completing multipart upload:', error);
    if (subscriptionDetails?.current_plan === 'Free') {
      autoSignIn();
    }
    throw error; // Rethrow the error to be handled by the caller
  }
}

const convertToBase64 = async (file) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

function base64ToFile(base64String, fileName) {
  const byteString = atob(base64String);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i += 1) {
    uint8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
  const file = new File([blob], fileName, { type: 'image/jpeg' });
  return file;
}

useEffect(() => {
  const totalItems = selectedItemsInBulk?.length;
  const itemsWithThumbnailsAndCategory = selectedItemsInBulk?.filter(
    (item) => Array.isArray(item?.thumbnails) && item.thumbnails?.length > 0 && item?.category
  )?.length;
  if (itemsWithThumbnailsAndCategory === totalItems) {
    setButtonText('Publish');
  } else if (itemsWithThumbnailsAndCategory > 0) {
    setButtonText('Publish & Save');
  } else {
    setButtonText('Save to Draft');
  }
}, [selectedItemsInBulk]);
const ChangeButton = (newCategoryStates, updatedVideoFiles, singleArray) => {
  const mergeArrays = (array1, array2) => {
    const array2Map = new Map(array2.map((item) => [item?.draftId, item]));
    const result = array1.filter((item) => !array2Map.has(item?.draftId)).concat(array2);
    return result;
};
const filteredNewCategoryStates = singleArray === 'singleArray' ? mergeArrays(newCategoryStates, selectedItemsInBulk) : newCategoryStates?.filter((categoryState) => updatedVideoFiles?.some((videoFile) => videoFile.draftId === categoryState.draftId));
  let c = 0;
  let t = 0;
  newCategoryStates.forEach((item, index) => {
    if (item?.category !== '') {
      c += 1;
    }
  });
  Object.values(videoThumbnails64)?.forEach((item) => {
    if (item !== undefined) {
      t += 1;
    }
  });
  const allHaveThumbnails = selectedItemsInBulk?.every(
    (item) => Array.isArray(item.thumbnails) && item.thumbnails.length > 0
  );
  const allHaveCategory = filteredNewCategoryStates?.every((item) => item.category && item.category !== '');
  const someHaveCategory = filteredNewCategoryStates?.some((item) => item.category && item.category !== '');
  // if (thumbnailAndCategoryStatus === 'every') {
  //   setButtonText('Publish');
  // } else if (thumbnailAndCategoryStatus === 'some') {
  //   setButtonText('Publish & Save');
  // } else {
  //   setButtonText('Save to Draft');
  // }
};

// Function for backend fallback
const callBackendThumbnailGeneration = (file, index) => {
  if (thumbnailMetadata[file.draftId]?.type === 'custom') {
    const customBase64Thumbnail = thumbnailMetadata[file.draftId]?.base64Custom;
    setVideoThumbnails64((prev) => ({
      ...prev,
      [file.draftId]: customBase64Thumbnail,
    }));
    setThumbnailGenerationStatus((prev) => ({ ...prev, [file.draftId]: 'success' }));
    return;
  }
  setThumbnailGenerationStatus((prev) => ({ ...prev, [file.draftId]: 'fallback' }));
  const videoFilePiece = new FormData();
  videoFilePiece.append('video_file', file.slice(0, 5 * 1024 * 1024)); // Send first 5MB of the video file
  const apiUrl = 'https://conf.eq7.in/generate-thumbnail/';

  fetch(apiUrl, {
    method: 'POST',
    body: videoFilePiece,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.thumbnail) {
        const backendThumbnail = `data:image/png;base64, ${data.thumbnail}`;
        const byteCharacters = atob(data.thumbnail);
        const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' });
        const backendThumbnailFile = new File([blob], `${file.draftId}_thumbnail.png`, { type: 'image/png' });
        // Update both videoThumbnails and videoThumbnails64 with backend thumbnail
        setVideoThumbnails((prevThumbnails) => ({
          ...prevThumbnails,
          [file.draftId]: [backendThumbnailFile], // Add backend thumbnail to videoThumbnails
        }));

        setVideoThumbnails64((prev) => ({
          ...prev,
          [file.draftId]: backendThumbnail, // Store backend thumbnail in videoThumbnails64 as base64
        }));

        setThumbnailGenerationStatus((prev) => ({ ...prev, [file.draftId]: 'success' }));
      } else {
        setThumbnailGenerationStatus((prev) => ({ ...prev, [file.draftId]: 'failed' }));
      }
    })
    .catch((error) => {
      console.error('Error uploading file', error);
      setThumbnailGenerationStatus((prev) => ({ ...prev, [file.draftId]: 'failed' }));
    });
};

const mediaThumGenerator = (file, index) => {
  // Check if a custom thumbnail is available in the metadata
  if (thumbnailMetadata[file.draftId]?.type === 'custom') {
    const customThumbnailBase64 = thumbnailMetadata[file.draftId]?.base64Custom;
    setVideoThumbnails64((prev) => ({
      ...prev,
      [file.draftId]: customThumbnailBase64,
    }));
    setThumbnailGenerationStatus((prev) => ({ ...prev, [file.draftId]: 'success' }));
    return;
  }

  // Check if videoThumbnails already contains 6 thumbnails for the current draftId
  if (videoThumbnails[file.draftId]?.length === 6) {
    console.log('6 thumbnails already generated, skipping...');
    return;
  }

  const video = document.createElement('video');
  video.src = URL.createObjectURL(file);
  if (thumbnailGenerationStatus[file.draftId] !== 'failed' && thumbnailGenerationStatus[file.draftId] !== 'success') {
    setThumbnailGenerationStatus((prev) => ({ ...prev, [file.draftId]: 'generating' }));
  }

  const numThumbnails = 6;
  const thumbnails = [];
  let captureIndex = 0;
  let interval;
  let firstThumbnailCaptured = false;
  let backendTriggered = false; // Flag to prevent multiple backend calls
  const triggerBackendFallback = () => {
    if (firstThumbnailCaptured === false && thumbnailGenerationStatus[file.draftId] !== 'failed' && thumbnailGenerationStatus[file.draftId] !== 'success') {
      backendTriggered = true;
      console.log('Fallback to backend.');
      callBackendThumbnailGeneration(file, index);
    }
  };

  video.addEventListener('loadeddata', () => {
    interval = video.duration / (numThumbnails + 1);
    video.currentTime = interval;
  });

  video.addEventListener('seeked', () => {
    if (captureIndex < numThumbnails) {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        if (blob) {
          const thumbnailFile = new File([blob], `thumbnail_${index}_${captureIndex}.jpg`, { type: 'image/jpeg' });
          thumbnails.push(thumbnailFile);

          if (!firstThumbnailCaptured) {
            const reader = new FileReader();
            reader.onloadend = () => {
              const data64 = reader.result;
              setVideoThumbnails64((prev) => ({
                ...prev,
                [file.draftId]: data64,
              }));
              firstThumbnailCaptured = true;
              setThumbnailGenerationStatus((prev) => ({
                ...prev,
                [file.draftId]: 'one generated',
              }));
            };
            reader.readAsDataURL(thumbnailFile);
          }
// eslint-disable-next-line
          captureIndex++;
          if (captureIndex < numThumbnails) {
            video.currentTime = interval * (captureIndex + 1);
          }

          if (thumbnails.length === numThumbnails) {
            setVideoThumbnails((prevThumbnails) => ({
              ...prevThumbnails,
              [file.draftId]: thumbnails,
            }));
            setThumbnailGenerationStatus((prev) => ({ ...prev, [file.draftId]: 'success' }));
          }
        } else {
          console.error('Failed to create a valid thumbnail blob');
          triggerBackendFallback();
        }
      }, 'image/jpeg', 0.9);
    }
  });

  video.addEventListener('error', (error) => {
    console.error('Error generating thumbnails:', error);
    triggerBackendFallback();
  });

  setTimeout(() => {
    if (thumbnails.length === 0) {
      console.log('Timeout reached.');
      triggerBackendFallback();
    }
  }, 45000);
};

const addCustomThumbnail = async (e, obj, index) => {
  const file = e.target.files[0];
  if (file) {
    setIsThumbnailChanged((prev) => ({
      ...prev,
      [obj.draftId]: true,
    }));
    // Update videoThumbnails with the new custom thumbnail
    setVideoThumbnails((prev) => {
      const existingThumbnails = prev[obj.draftId] || [];
      // Check if the last thumbnail in the array is a custom thumbnail
      const hasCustomThumbnail = existingThumbnails.length > 0 && thumbnailMetadata[obj.draftId]?.type === 'custom';
      return {
        ...prev,
        [obj.draftId]: hasCustomThumbnail
          ? [...existingThumbnails.slice(0, -1), file] // Replace the last custom thumbnail
          : [...existingThumbnails, file],
      };
    });

    try {
      // Convert file to base64
      const base64String = await convertToBase64(file);

      // Update thumbnailMetadata to store the new custom thumbnail
      setThumbnailMetadata((prev) => ({
        ...prev,
        [obj.draftId]: {
          type: 'custom',
          customThumbnail: file,
          base64Custom: base64String,
        },
      }));

      // Update videoThumbnails64 to store the base64 string of the custom thumbnail
      setVideoThumbnails64((prevThumbnails) => ({
        ...prevThumbnails,
        [obj.draftId]: base64String,
      }));

      // Set the newly added custom thumbnail as the selected one
      setSelectedThumbnail((prev) => ({
        ...prev,
        [obj.draftId]: file, // Set the custom thumbnail as the selected one
      }));
      /* eslint-disable */
      setSelectedItemsInBulk((prevItems) =>
        prevItems.map((item) =>
          item.draftId === obj.draftId
            ? { ...item, thumbnails: Array.isArray(item.thumbnails) ? [...item.thumbnails, file] : [file] } // Add custom thumbnail to the array
            : item
        )
      );
      /* eslint-enable */
      ChangeButton(selectedItemsInBulk);
    } catch (error) {
      console.error('Failed to convert file to base64:', error);
    }
  }
};

const deleteThumbnail = (e, obj, index) => {
  e.stopPropagation(); // Prevent triggering other click events if needed

  setVideoThumbnails((prev) => {
    const updatedThumbnails = { ...prev };
    delete updatedThumbnails[obj.draftId];
    return updatedThumbnails;
  });

  setThumbnailMetadata((prev) => {
    const updatedMetadata = { ...prev };
    delete updatedMetadata[obj.draftId];
    return updatedMetadata;
  });

  setVideoThumbnails64((prevThumbnails) => {
    const updatedThumbnails64 = { ...prevThumbnails };
    delete updatedThumbnails64[obj.draftId];
    return updatedThumbnails64;
  });
  mediaThumGenerator(obj, index);
};
function formatFileSize(sizeInBytes) {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const gigabyte = megabyte * 1024;
  if (sizeInBytes >= gigabyte) {
    return `${(sizeInBytes / gigabyte).toFixed(2)} GB`;
  }
  if (sizeInBytes >= megabyte) {
    return `${(sizeInBytes / megabyte).toFixed(2)} MB`;
  }
  if (sizeInBytes >= kilobyte) {
    return `${(sizeInBytes / kilobyte).toFixed(2)} KB`;
  }
  return `${sizeInBytes} Bytes`;
}
let dragCounter = 0;
const dragOver = (e) => {
  e.preventDefault();
  if (dragCounter === 0) {
  setDroper(true);
  }
// eslint-disable-next-line no-plusplus
  dragCounter++;
};
const dragLeave = (e) => {
// eslint-disable-next-line no-plusplus
  dragCounter--;
  e.preventDefault();
  if (dragCounter === 0) {
  setDroper(false);
  }
};
useEffect(() => {
  videoFiles.map((obj, i) => {
    const { draftId } = obj;
    const fileNameWithoutExtension = obj.name.split('.').slice(0, -1).join('.');

    if (test[draftId]) {
      updateTitle(draftId, test[draftId]);
    } else {
      updateTitle(draftId, fileNameWithoutExtension);
    }

    if (!videoThumbnails[i] || videoThumbnails[i].length < 2) {
      mediaThumGenerator(obj, i);
    }
    return false;
  });
  setT(true);
}, [videoFiles]);

// const handleCheckboxChange = (name, size) => {
//   setSelectedItemsInBulk((prevItems) => {
//     const isSelected = prevItems.some((item) => item.name === name);
//     if (isSelected) {
//       return prevItems.filter((item) => item.name !== name);
//     }
//     return [
//       ...prevItems,
//       {
//         token: getToken(),
//         draftId: '',
//         group_ids: [],
//         thumbnails: '',
//         name,
//         description: '',
//         category: '',
//         playlist: [],
//         visibility: '',
//         size,
//       },
//     ];
//   });
// };

// const handlePlaylistChange = (event, index) => {
//   setSelectedItemsInBulk((prevItems) => {
//     const newItems = [...prevItems];
//     newItems[index] = {
//       ...newItems[index],
//       playlist: event.target.value,
//     };
//     return newItems;
//   });
// };
const [loadStatus, setLoadStatus] = useState({}); // Keeps track of each image's load status

const handleImageLoad = (index) => {
  setLoadStatus((prevStatus) => ({
    ...prevStatus,
    [index]: 'loaded',
  }));
};

const handleImageError = (index) => {
  setLoadStatus((prevStatus) => ({
    ...prevStatus,
    [index]: 'error',
  }));
};
const [arrayUpdater, setArrayUpdater] = useState(false);
const [isSyncEnabled, setIsSyncEnabled] = useState(false); // Switch state to control sync
const [lastSelectedCategory, setLastSelectedCategory] = useState({ value: '', name: '' });

const handleCategoryChange = (event, index) => {
if (event.target.value !== undefined) {
  setAddingNewCategory((prevState) => {
    const updatedState = {};
    Object.keys(prevState).forEach((key) => {
      if (prevState[key] === true) {
        updatedState[key] = false;
      }
    });
    return updatedState;
  });
  const newCategoryStates = [...selectedItemsInBulk]; // Copy the current state
  const selectedCategoryValue = event.target.value;
  // Find the selected category name based on the value
  const selectedCategoryName = categrory.find(
    (categoryOption) => categoryOption.value === selectedCategoryValue
  )?.name || 'Default';

  if (isSyncEnabled) {
    // If sync is enabled, apply the selected category to all items
    const updatedCategoryStates = newCategoryStates.map((item) => ({
      ...item,
      category: selectedCategoryValue,
      categoryName: selectedCategoryName,
    }));

    // Update the state and ChangeButton with the synced categories
    ChangeButton(updatedCategoryStates, videoFiles);
    setSelectedItemsInBulk(updatedCategoryStates);
  } else {
    // If sync is off, update only the selected item
    newCategoryStates[index] = {
      ...newCategoryStates[index],
      category: selectedCategoryValue,
      categoryName: selectedCategoryName,
    };

    // Track the last selected category
    setLastSelectedCategory({ value: selectedCategoryValue, name: selectedCategoryName });

    // Update the state and ChangeButton with the updated individual category
    ChangeButton(newCategoryStates, videoFiles);
    setSelectedItemsInBulk(newCategoryStates);
  }
  setArrayUpdater(!arrayUpdater); // Trigger the re-render if needed
}
};

const handleSwitchToggle = (event) => {
  const isSwitchOn = event.target.checked;
  setIsSyncEnabled(isSwitchOn);
  setAddingNewCategory((prevState) => {
    const updatedState = {};
    Object.keys(prevState).forEach((key) => {
      if (prevState[key] === true) {
        updatedState[key] = false;
      }
    });
    return updatedState;
  });
  if (isSwitchOn && lastSelectedCategory) {
    // When the switch is turned on, sync all items to the last selected category
    const updatedCategoryStates = selectedItemsInBulk.map((item) => ({
      ...item,
      category: lastSelectedCategory.value,
      categoryName: lastSelectedCategory.name,
    }));

    // Update all items with the last selected category
    ChangeButton(updatedCategoryStates, videoFiles);
    setSelectedItemsInBulk(updatedCategoryStates);
  }
};

const handleInputChange = (e, index) => {
  e.preventDefault(); // Prevent default behavior
  setTitle(e.target.value);
};

const handleSubmit = async (e, index) => {
  setAddEditCategory(true);
  setIsSyncEnabled(false);
  e.preventDefault(); // Prevents page reload
  const payload = {
    category_id: '',
    icon: '1',
    title, // The text entered in the input field
    token: getToken(), // Assuming you have a getToken function
  };
  try {
    const response = await axios.post(`${baseUrl}/addEditCategory`, payload);
    console.log('Success:', response.data); // Handle success
    fetchCategories();
    const { category_id, title: newCategoryName } = response.data; // Extract the new category details
    // After successful submission, update the selected category for the specific file
    setSelectedItemsInBulk((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index] = {
        ...updatedItems[index],
        category: category_id, // Use the new category ID from the response
        categoryName: title, // Use the new category name from the response
      };
      return updatedItems;
    });
    setLastSelectedCategory({ value: category_id, name: title });
    setAddingNewCategory((prev) => ({
      ...prev,
      [index]: false, // Update only the specific index
    }));
    setTitle('');
    setAddEditCategory(false);
  } catch (error) {
    console.error('Error:', error); // Handle error
    setAddEditCategory(false);
  }
};
const handleAddNewClick = (index) => {
  setIsSyncEnabled(false);

  // Close any open "Add New" inputs and open the clicked one
  setAddingNewCategory((prev) => {
    const newCategoryState = {};

    // Set all items to false except the one that was clicked
    Object.keys(prev).forEach((key) => {
      newCategoryState[key] = false;
    });

    newCategoryState[index] = true; // Open the clicked one
    return newCategoryState;
  });
};

const handleDescriptionChange = (event, index) => {
  const { value } = event.target;
  setSelectedItemsInBulk((prevItems) => prevItems.map((item, i) => (i === index ? { ...item, description: value } : item)));
};
const visibilityText = langText?.visibility;
const videoVisibilityOptions = [
  { name: 'All Viewers' || visibilityText?.publicDescription, value: '2' },
  { name: visibilityText?.privateDescription || 'Only I can view (Private)', value: '1' },
  { name: 'Choose a group', value: '3' },
];
const [openGroups, setOpenGroups] = useState(Array(selectedItemsInBulk.length).fill(false));

const handleVisibilityChange = (event, index) => {
  const newVisibilityStates = [...selectedItemsInBulk];
  newVisibilityStates[index] = {
    ...newVisibilityStates[index],
    visibility: event.target.value
  };
  const newOpenGroups = Array.isArray(openGroups) ? [...openGroups] : [];
  newOpenGroups[index] = event.target.value === '3';
  setSelectedItemsInBulk(newVisibilityStates);
  setOpenGroups(newOpenGroups);
};
const handleMenuItems = (index, i) => {
  const newOpenGroups = Array.isArray(openGroups) ? [...openGroups] : [];
  newOpenGroups[i] = true;
  if (index === 2) {
  setOpenGroups(newOpenGroups);
} else {
  setOpenGroups(false);
}
  };
const handleMenuItemClick = (index) => {
  const newOpenGroups = [...openGroups];
  newOpenGroups[index] = false;
  setOpenGroups(newOpenGroups);
};
const updateUploadPercentages = (draft_id, data) => {
  dispatch(saveUploadInfo({ [draft_id]: data }));
};
const notify = (filename, draft_id, uploadPercentages, updateUploadPercentages) => {
  setTimeout(() => {
    const newPercentages = { ...uploadPercentages, [draft_id]: 'Starting Upload' };
    tempPercentages = newPercentages;
    updateUploadPercentages(draft_id, 'Starting Upload');
    const trimmedFilename = filename.length > 26 ? `${filename.substring(0, 26)}...` : filename;
    toast(trimmedFilename, {
      autoClose: false,
      toastId: draft_id,
      position: toast.POSITION.BOTTOM_RIGHT,
      closeButton: <CustomCloseButton draft_id={draft_id} updateUploadPercentages={updateUploadPercentages} />,
      toastClassName: 'custom-toast'
    });
  }, 0);
};
async function uploadPartSequentially(file, bucket, key, uploadId, partNumber, start, partSize, parts, draft_id, startTime) {
  const end = Math.min(start + partSize, file.size);
  const data = {};
  const dataCallback = (snapshot) => {
    if (snapshot.exists()) {
      const dataObj = snapshot.val();
      data[draft_id] = dataObj[draft_id];
    }
  };
  onValue(databaseRef, dataCallback);
  const unsubscribe = () => {
    off(databaseRef, 'value', dataCallback);
  };
  if (data[draft_id]?.status !== 'DELETED') {
    await uploadPart(getBucketName(userId), key, partNumber, file.slice(start, end), uploadId, setVideoUploadedParts, file.name, draft_id, uploadPercentages, updateUploadPercentages, partSize, startTime, file.size)
    .then((item) => {
      parts.push({ PartNumber: partNumber, ETag: item.ETag });
    });
  } else {
    unsubscribe();
  }
  unsubscribe();
  if (end < file.size) {
    await uploadPartSequentially(file, bucket, key, uploadId, partNumber + 1, end, partSize, parts, draft_id, startTime);
  }
}

async function initMultipartUpload(bucket, key) {
  const params = {
    Bucket: bucket,
    Key: key,
  };

  return s3.createMultipartUpload(params).promise();
}

const updateDummy = (dummy, status = 0) => {
  if (status === 1) {
    dispatch(saveDummy({ [dummy?.draft_id]: dummy }));
  } else {
    dispatch(removeDummy(dummy));
  }
};
const handleEncouter = (draft_id, updateUploadPercentages) => {
  if (uploadQueue?.length <= 1) {
    setOpen();
      }
  toast.update(draft_id, {
    closeButton: <CustomCloseloader />,
  });

  const databaseRef = ref(firebaseDatabase, 'status_from_sns');
  const changeFieldFromFirebase = (draft_id, statusString) => {
    const dataToUpdate = {
      [draft_id]: {
        status: statusString,
      },
    };
    update(databaseRef, dataToUpdate);
  };
  changeFieldFromFirebase(draft_id?.draft_id ? draft_id?.draft_id : draft_id, 'DELETED');
  updateDummy(draft_id?.draft_id ? draft_id?.draft_id : draft_id, 0);
  setIamUploading(false);
  setActiveUploads((prevUploads) => prevUploads?.filter((id) => id !== draft_id));
  updateUploadPercentages(draft_id?.draft_id ? draft_id?.draft_id : draft_id, 'Cancelled');
  closeToast(draft_id?.draft_id ? draft_id?.draft_id : draft_id, 'Upload has been canceled by user', 'error');
};

const backToOld = ({ draft_id, updateUploadPercentages }) => {
  toast.update(draft_id, {
    closeButton: <CustomCloseButton draft_id={draft_id} updateUploadPercentages={updateUploadPercentages} />,
  });
};
const CustomCloseDiv = (draft_id, updateUploadPercentages) => (
  <div className={styles.customDiv}>
    Are you sure you want to cancel ?
    <div className={styles.fleo}>
      <button className={styles.cancelPlz} onClick={() => backToOld(draft_id, updateUploadPercentages)}>
      Exit
      </button>
      <button className={styles.noCancel} onClick={() => handleEncouter(draft_id, updateUploadPercentages)}>
        Continue to cancel
      </button>
    </div>
  </div>
);
const handleClick = (draft_id, updateUploadPercentages) => {
  toast.update(draft_id, {
    closeButton: <CustomCloseDiv draft_id={draft_id} updateUploadPercentages={updateUploadPercentages} />,
  });
};
const CustomCloseloader = () => (
  <button className={styles.closeMeBro}>
  <Spinner />
  </button>
);

const CustomCloseButton = ({ draft_id, updateUploadPercentages }) => (
  <button className={styles.closeMeBro} onClick={() => handleClick(draft_id, updateUploadPercentages)}>
    <CloseIcon />
  </button>
);

async function uploadLargeFile(bucket, key, file, draft_id, index, fileName) {
  setUploadContainer((prevUploadContainer) => [...prevUploadContainer, draft_id]);
  try {
    notify(file.name, draft_id, uploadPercentages, updateUploadPercentages);
    const { UploadId } = await initMultipartUpload(bucket, fileName);
    const minimumPartSize = 5 * 1024 * 1024; // 5MB -- aws s3 bucket requires atlease 5MB/packet currently
    let partSize = file.size / 100; // splitting to 100 packets to perform 1 packet as for 1 percent
    if (partSize < minimumPartSize) {
      partSize = minimumPartSize;
    }
    const fileSize = file.size;
    const partCount = Math.ceil(fileSize / partSize);
    const startTime = new Date().getTime();
    const parts = [];
    iteratorNum[draft_id] = 0;
    totalNum[draft_id] = partCount;
    await uploadPartSequentially(file, bucket, fileName, UploadId, 1, 0, partSize, parts, draft_id, startTime);
    const data = {};
    const dataCallback = (snapshot) => {
      if (snapshot.exists()) {
        const dataObj = snapshot.val();
        data[draft_id] = dataObj[draft_id];
      }
    };
    onValue(databaseRef, dataCallback);
    const unsubscribe = () => {
      off(databaseRef, 'value', dataCallback);
    };
    if (data[draft_id]?.status !== 'DELETED') {
      const uploadedData = await completeMultipartUpload(bucket, fileName, parts, UploadId, file, index);
      const newPercentages = { ...uploadPercentages, [draft_id]: 'Please wait' };
      tempPercentages = newPercentages;
      updateUploadPercentages(draft_id, 'Please wait');
      iteratorNum[file.name] = 0;
      totalNum[draft_id] = 0;
      setIamUploading(true);
    } else {
      updateUploadPercentages(draft_id, 'Cancelled');
      closeToast(draft_id, 'Upload has been canceled by user', 'error');
      unsubscribe();
    }
    unsubscribe();
  } catch (error) {
    if (localStorage.getItem('opened_draft_id') === draft_id) {
      // hideModal();
    }
    updateUploadPercentages(draft_id, 'Cancelled');
    closeToast(draft_id, 'Upload has been canceled by user', 'error');
    const newPercentages = { ...uploadPercentages, [draft_id]: 'Cancelled' };
    tempPercentages = newPercentages;
    updateUploadPercentages(draft_id, 'Cancelled');
    updateUploadDetails(draft_id, { elapsed_size: 0, elapsed_time: 0, remaining_size: 0 });
    const allPropertiesAreNumericOrString = Object.values(uploadPercentages).some((value) => typeof value === 'number' || value === 'Starting Upload' || value === 'Initializing');
    if (allPropertiesAreNumericOrString) {
      setIamUploading(false);
    } else setIamUploading(true);
  }
}

function changeSize(size) {
  const newSize = size / 1024 / 1024;
  return newSize.toFixed(2);
}

async function handleInitMultipartUpload() {
  // setOpen(false);
  setSelectedFiles([]);
  videoFiles.forEach((file, index) => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const number = Math.floor(Math.random() * 10000) + 1;
    const fileName = `${Date.now()}-${userId}-video-${number}.${fileExtension}`;
    // Add file to the upload queue
    setUploadQueue((prevQueue) => [
      ...prevQueue,
      { file, index, fileName, draftId: file.draftId, details: selectedItemsInBulk[index] }
    ]);

    const currentDate = new Date();
    const date = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const formattedDate = `${String(month).padStart(2, '0')}/${String(date).padStart(2, '0')}/${year}`;
    let type_of;
    let contentStatus;
    console.log('typeChecker', selectedItemsInBulk[index].category, videoThumbnails[file.draftId], selectedItemsInBulk[index].visibility);
    if (selectedItemsInBulk[index].category && videoThumbnails[file.draftId] && selectedItemsInBulk[index].visibility && videoThumbnails64[file.draftId] !== undefined) {
      type_of = 0;
      contentStatus = 'Video';
    } else {
      type_of = 2;
      contentStatus = 'Draft';
    }
    const dummy = {
      draft_id: file.draftId,
      title: test[file.draftId] || fileName,
      thumbnail: videoThumbnails64[file.draftId],
      category: selectedItemsInBulk[index].categoryName,
      video_description: selectedItemsInBulk[index].description,
      created: formattedDate,
      video_size: changeSize(file?.size),
      type: type_of,
      group_id: selectedItemsInBulk[index]?.group_ids,
      content_status: contentStatus,
      uploadIsTrying: 1,
    };
    updateDummy(dummy, 1);
  });
  processUploadQueue();
  setIsVisible(false);
  setOpen();
  setNavigateOnceClicked(true);
  // setTimeout(() => {
  // }, 300);
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const handleDelete = (id, index) => {
  // Step 1: Update videoThumbnails by removing the specified id
  const updatedVideoThumbnails = { ...videoThumbnails };
  delete updatedVideoThumbnails[id];
  setVideoThumbnails(updatedVideoThumbnails);

  // Step 2: Update videoThumbnails64 by removing the base64 string for the specified id
  const updatedVideoThumbnails64 = { ...videoThumbnails64 };
  delete updatedVideoThumbnails64[id];
  setVideoThumbnails64(updatedVideoThumbnails64);

  // Step 3: Update selectedItemsInBulk and videoFiles arrays by filtering out the deleted item
  const updatedSelectedItemsInBulk = selectedItemsInBulk.filter((item) => item?.draftId !== id);
  setSelectedItemsInBulk(updatedSelectedItemsInBulk);
  const updatedVideoFiles = videoFiles.filter((file) => file.draftId !== id);
  setVideoFiles(updatedVideoFiles);
  setVideoGroup((prevVideoGroup) => {
    const updatedVideoGroup = [...prevVideoGroup];
    updatedVideoGroup.splice(index, 1);
    return updatedVideoGroup;
  });
  if (updatedVideoFiles.length === 0) {
    setOpen();
  }

  // Step 5: Update the title and button state
  updateTitle(index, null, 0);
  ChangeButton(updatedSelectedItemsInBulk, updatedVideoFiles);

  // Step 6: Toggle arrayUpdater state to trigger any necessary re-renders
  setArrayUpdater((prev) => !prev);
};

const [inputTrigger, setInputTrigger] = useState(false);

const MAX_SIZE_GB = 30;
const MAX_SIZE_BYTES = MAX_SIZE_GB * 1024 * 1024 * 1024;
const allowedExtensions = ['mp4', 'mov', 'mpg', 'm4v', 'm2ts'];
const scrollRef = useRef(null);
const handleFileChange = (event) => {
  dragCounter = 0;
  setDroper(false);
  event.preventDefault(); // Prevent default behavior for both drag-and-drop and file input

  // Determine the source of files: Drag and drop (dataTransfer) or file input (target.files)
  const newFiles = Array.from(event.dataTransfer?.files || event.target.files);

  newFiles.forEach((file) => {
    // eslint-disable-next-line
    file.draftId = uuid(); // Add unique ID
    // eslint-disable-next-line
    file.visibility = '2'; // Add visibility status
  });

  // Validate file formats
  const invalidFiles = newFiles.filter((file) => {
    const extension = file.name.split('.').pop().toLowerCase();
    return !allowedExtensions.includes(extension);
  });

  if (invalidFiles.length > 0) {
    setAppSnackbar({ isVisible: true, type: 'error', message: 'Invalid file format. Allowed formats are: .mp4, .mov, .mpg, .m4v, .m2ts' });
    return;
  }

  const combinedFiles = [...videoFiles, ...newFiles];
  const totalSize = combinedFiles.reduce((acc, file) => acc + file.size, 0);

  // Validate file count and total size
  if (combinedFiles.length > MAX_VIDEOS) {
    setAppSnackbar({ isVisible: true, type: 'error', message: `You can only select ${MAX_VIDEOS} videos` });
    return;
  }

  if (totalSize > MAX_SIZE_BYTES) {
    setAppSnackbar({ isVisible: true, type: 'error', message: `Total file size should not exceed ${MAX_SIZE_GB}GB.` });
    return;
  }
  // Update state with valid files
  setSelectedItemsInBulk((prevItems) => [
    ...prevItems,
    ...newFiles.map((file) => ({
      token: getToken(),
      group_ids: [],
      draftId: file.draftId,
      thumbnails: '',
      name: file.name,
      description: '',
      category: '',
      playlist: [],
      visibility: file.visibility,
      size: file.size,
    })),
  ]);

  setVideoFiles((prevFiles) => [...prevFiles, ...newFiles]);
  ChangeButton(combinedFiles, null, 'singleArray');
  setIsSyncEnabled(false);
  setTimeout(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, 0);
};

const groupRefs = useRef([]);
const setGroupRef = (index) => (element) => {
  groupRefs.current[index] = element;
};

const label = { inputProps: { 'aria-label': 'Select all' } };
useEffect(() => {
  function handleClickOutside(event) {
    // setAddingNewCategory((prevState) => {
    //   const updatedState = { ...prevState }; // Copy previous state
    //   Object.keys(prevState).forEach((key) => {
    //     if (prevState[key] === true) {
    //       updatedState[key] = false; // Only set true values to false
    //     }
    //   });
    //   return updatedState;
    // });
    groupRefs.current.forEach((ref, index) => {
      if (ref && !ref.contains(event.target)) {
        const newOpenGroups = [...openGroups];
        newOpenGroups[index] = false;
        setOpenGroups(newOpenGroups);
      }
    });
  }
  document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
}, [openGroups, setOpenGroups]);

const handleThumbnailClick = async (file, draftId) => {
  try {
    setIsThumbnailChanged((prev) => ({
      ...prev,
      [draftId]: true,
    }));
    const base64String = await convertToBase64(file);
    setVideoThumbnails64((prev) => ({
      ...prev,
      [draftId]: base64String,
    }));
    setSelectedThumbnail((prev) => ({
      ...prev,
      [draftId]: file,
    }));
    setThumbnailMetadata((prev) => ({
      ...prev,
      [draftId]: {
        type: 'custom',
        customThumbnail: file,
        base64Custom: base64String,
      },
    }));
  } catch (error) {
    console.error('Failed to convert thumbnail to base64:', error);
  }
};
console.log('selectedItemsInBulk', selectedItemsInBulk);
console.log('thumbnailGenerationStatus', thumbnailGenerationStatus);
  return (
    <div
    onDrop={handleFileChange}
    onDragOver={(e) => dragOver(e)}
    onDragLeave={(e) => dragLeave(e)}
    style={{
      display: isVisible ? 'block' : 'none',
    }}
      className={styles.positioningModel}
      onKeyPress={undefined}
      role="button"
      tabIndex={0}
      // onClick={(event) => handleClose(event)}
    >
      {droper
&& (
<div
  role="button" // Adds the appropriate role
  tabIndex={0} // Makes it focusable by keyboard
  onClick={() => { setDroper(false); dragCounter = 0; }}
  onKeyPress={(event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      dragCounter = 0; // Also handles Enter/Space key press for accessibility
    }
  }}
  className={styles.bigCover}
>
  <img className={styles.imgCenter} src={CloudUpl} alt="" />
</div>

)}
      <div
        onKeyPress={undefined}
        role="button"
        tabIndex={0}
        onClick={(event) => {
          event.stopPropagation();
        }}
        className={styles.bringCenter}
      >
        <div className={styles.heading}>
          Must Choose Video Category For Each Video In Order To Publish That Video
        </div>
        <div className={styles.headingh3}>
          To Add Subcategory, Change Thumbnail, And Add Video Description, Edit In Content Dashboard&gt;Content
          <div style={{ opacity: (!selectedItemsInBulk?.length || !selectedItemsInBulk?.every((item) => item?.draftId)) ? '0.5' : '1' }}>
<span>Apply Same Category to all videos</span>
          <Switch
            disabled={!selectedItemsInBulk?.length || !selectedItemsInBulk?.every((item) => item?.draftId)}
            checked={isSyncEnabled} // Bind switch state
            onChange={handleSwitchToggle}
          />
          </div>
        </div>
        <hr className={styles.linex} />
        <div className={styles.overScrollMe} ref={scrollRef}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
          <TableRow>
            {/* <TableCell>
              <Checkbox
              status={selectedItemsInBulk?.length === videoFiles?.length}
              />
            </TableCell> */}
            <TableCell>
              <div className={styles.TbleCel}>
              Content Preview
              </div>
            </TableCell>
            <TableCell className={styles.TbleCel}>
              <div className={styles.TbleCel}>
                Video Title
              </div>
            </TableCell>
            {/* <TableCell>
              Description
            </TableCell> */}
            <TableCell className={styles.TbleCel}>
              <div className={styles.TbleCel}>
            Category
              </div>
            </TableCell>
            {/* <TableCell>
              Playlist
            </TableCell> */}
            <TableCell className={styles.TbleCel}>
              <div className={styles.TbleCel}>
              Visiblity
              </div>
            </TableCell>
            <TableCell className={styles.TbleCel}>
              <div className={styles.TbleCel}>
              Size
              </div>
            </TableCell>
            <TableCell className={styles.TbleCel}>
              <div className={styles.TbleCel}>
              Actions
              </div>
            </TableCell>
          </TableRow>
          </TableHead>
         { t === true ? (
            <TableBody>
            {videoFiles?.map((obj, i) => (
              <TableRow key="" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {/* <TableCell>
                  <Checkbox
                    status={selectedItemsInBulk?.some((item) => item?.name === obj?.name)}
                    onClick={() => handleCheckboxChange(obj?.name, obj?.size)}
                  />
                </TableCell> */}
<TableCell>
  {/* Hidden video element for thumbnail generation, if needed */}
  <video ref={videoRef} style={{ display: 'none' }} controls />

  <div className={styles.previewContainer}>
    {videoThumbnails64[obj?.draftId] ? (
      <img
        className={styles.previewImage}
        src={(() => {
          try {
            return videoThumbnails64[obj?.draftId];
          } catch (error) {
            console.error('Failed to create object URL:', error);
            return noImageAvailable; // Fallback image
          }
        })()}
        alt={obj?.title || 'Video thumbnail'}
        onLoad={(e) => URL.revokeObjectURL(e.target.src)} // Clean up URL
      />
    ) : (
      <img
        style={{ objectFit: 'contain' }}
        className={styles.previewImage}
        src={thumbnailGenerationStatus[obj?.draftId] === 'failed' ? noImageAvailable : generatingThumbnail}
        alt={obj?.title || 'No thumbnail available'}
      />
    )}

    {/* Input field for selecting a custom thumbnail, always visible */}
    <input
      type="file"
      accept="image/*"
      style={{ display: 'none' }}
      id={`file-input-${obj.draftId}`}
      onChange={(e) => addCustomThumbnail(e, obj, i)}
    />
        {/* Icon to trigger file input */}
        <label
      htmlFor={`file-input-${obj.draftId}`}
      className={styles.iconButton}
      // style={{
      //   opacity: videoThumbnails[obj?.draftId] instanceof File ? '' : 1,
      // }}
        >
      <img src={Add} alt="Add thumbnail" />
        </label>
  </div>
  <div
  // style={{ justifyContent: (thumbnailGenerationStatus[obj?.draftId] === 'generating' || thumbnailGenerationStatus[obj?.draftId] === 'fallback') && 'center' }}
  className={styles.flexibox}
  >
    {
  (thumbnailGenerationStatus[obj.draftId] !== 'failed' && thumbnailGenerationStatus[obj.draftId] !== 'success')
    ? <ThreeDotLoader colour="black" size="5px" />
    : videoThumbnails[obj.draftId]?.map((file, index) => {
        const url = URL.createObjectURL(file);
        return (
            /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-to-interactive-role */
          <div className={styles.eachThumbnails} key={index}>
            <img
            role="button"
            tabIndex={0}
            onClick={() => handleThumbnailClick(file, obj.draftId)}
            src={url}
            alt={`Thumbnail ${index + 1}`}
             onError={(e) => {
               e.target.src = '';
               e.target.alt = 'Failed to load thumbnail';
               e.target.style.opacity = 0.5;
              }}
              style={{
                opacity: selectedThumbnail !== null && selectedThumbnail[obj?.draftId || ''] === file ? 1 : 0.5,
                transition: 'opacity 0.3s ease',
              }}
            />
          </div>
            /* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-to-interactive-role */
        );
      })
}

  </div>
</TableCell>

                <TableCell component="th" scope="row">
                  <div className={styles.nameBal}>
                      <input type="text" placeholder="Title" value={test[obj.draftId]} onChange={(e) => updateTitle(obj.draftId, e.target.value)} className={styles.titles} />
                  </div>
                </TableCell>
                {/* <TableCell component="th" scope="row">
                <textarea className={styles.description} placeholder="Description" value={selectedItemsInBulk?.length > 0 ? selectedItemsInBulk[i]?.description : ''} onChange={(e) => handleDescriptionChange(e, i)} />
                </TableCell> */}
<TableCell sx={{ width: '233px', textAlign: 'center' }}>
                {categrory !== undefined
                  ? (addingNewCategory[i] ? (
                    // Show input form when adding a new category
                    <form className={styles.addnewForm} onSubmit={(e) => handleSubmit(e, i)}>
<button
  onClick={() => {
    setTitle('');
 setAddingNewCategory((prevState) => {
    const updatedState = { ...prevState }; // Copy previous state
    Object.keys(prevState).forEach((key) => {
      if (prevState[key] === true) {
        updatedState[key] = false; // Only set true values to false
      }
    });
    return updatedState;
  });
}}
  style={{
    background: 'transparent',
  }}
>
  <CloseIcon />
</button>

                      <input
                        type="text"
                        value={title}
                        className={styles.titles}
                        onChange={handleInputChange}
                        placeholder="Enter Category"
                      />
                      <button
style={{
    opacity: title === '' ? '0' : addEditCategory ? '0.5' : '1',
    cursor: title === '' ? 'not-allowed' : addEditCategory ? 'default' : 'pointer'
  }}
disabled={addEditCategory}
type="submit"
                      >
                      {addEditCategory ? (
  <Spinner spinex={spinerSmall} />
) : (
  'Add'
)}
                      </button>
                    </form>
                  ) : (
<div className={styles.tabletC}>
<button style={{ opacity: '0' }}>
<CloseIcon />
</button>
<FormControl key={i} sx={{ m: 1, width: 120 }}>
                    <InputLabel
                      htmlFor={`category-select-${i}`}
                      color="primary"
                    >
                      {categrory?.length < 0 ? 'No Category' : 'Category'}
                    </InputLabel>
                    <Select
                    onClick={(e) => handleCancel(e, i)}
                    value={
                      selectedItemsInBulk[i] !== undefined && selectedItemsInBulk[i]?.category
                        ? selectedItemsInBulk[i]?.category
                        : '' // Set to an empty string if no category is selected
                    }
                      onChange={(e) => handleCategoryChange(e, i)}
                      input={<OutlinedInput label="category" />}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                  <MenuItem
                  disableRipple
                  disableTouchRipple
                  onClick={() => handleAddNewClick(i)}
                  sx={{ color: '#5AA5EF', fontWeight: 500 }}
                  >
                  <Typography variant="inherit" noWrap>Add new</Typography>
                  </MenuItem>
                      {categrory?.length > 0
                        && categrory.map((categoryOption, index) => (
                          <MenuItem key={index} value={categoryOption.value || index + 1}>
                            {categoryOption.name || 'Default Name'}
                          </MenuItem>
                        ))}
                    </Select>
</FormControl>
<button style={{ opacity: '0' }}>
<CloseIcon />
</button>
</div>
                  )
                  )
                : <Spinner spinex={spinerSmall} />}
</TableCell>
                {/* <TableCell>
                    {options?.length > 0 ? (
                      <FormControl sx={{ m: 1, width: 200 }}>
                         <InputLabel htmlFor={`playlist-select-${i}`} color="primary">Playlist</InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={selectedItemsInBulk[i]?.playlist}
                          onChange={(e) => handlePlaylistChange(e, i)}
                          input={<OutlinedInput label="Playlist" />}
                          renderValue={(selected) => selected.map((item) => item.name).join(', ')}
                          MenuProps={MenuProps}
                        >
                          {selectedItemsInBulk[i]?.playlist?.length <= 0 && ( // Render the placeholder only when no playlist is selected
                            <MenuItem value="" disabled>
                              Select Playlist
                            </MenuItem>
                          )}
                          {options && options?.map((option, index) => (
                            <MenuItem key={index} value={option || index + 1}>
                              <StyledCheckbox
                                checked={selectedItemsInBulk[i]?.playlist?.includes(option) || false}
                              />
                              <ListItemText primary={option?.name || 'Default Name'} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : <Spinner spinex={spinerSmall} />}

                </TableCell> */}
                <TableCell>
                  <div className={styles.visibilityContainer}>
                       <FormControl sx={{ m: 1, width: 252 }}>
                          <Select
                            value={selectedItemsInBulk[i]?.visibility || '2'}
                            onChange={(e) => handleVisibilityChange(e, i)}
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                          {videoVisibilityOptions?.map((option, index) => (
                            <MenuItem onClick={() => handleMenuItems(index, i)} key={index} value={option?.value || index + 1}>
                              {option?.name || 'Default Name'}
                            </MenuItem>
                          ))}
                          </Select>
                       </FormControl>
                        {/* {selectedItemsInBulk[i].visibility === videoVisibilityOptions[2].value && ( */}
                        {openGroups[i] && (
                          <div ref={setGroupRef(i)}>
                            <GroupSelector
                              isBulk
                              onSelect={(val) => { selectGroup(val, i); }}
                              selectedOption={selectedItemsInBulk[i]?.group_ids || []}
                              setOpenGroups={setOpenGroups}
                              handleMenuItemClick={handleMenuItemClick}
                              index={i}
                            />
                          </div>
                        )}
                  </div>
                </TableCell>
                <TableCell>
                  <div className={styles.sizeBal}>
                {formatFileSize(obj.size)}
                  </div>
                </TableCell>
                <TableCell>
                {/* <div style={{ backgroundColor: 'none', cursor: 'pointer', width: 'auto', height: 'auto' }} > */}
                <button disabled={isLoading} onClick={() => !isLoading && handleDelete(obj.draftId, i)} style={{ backgroundColor: 'transparent', cursor: isLoading ? 'default' : 'pointer', opacity: isLoading ? '0.5' : '1' }}>
                  <img src={DeleteIcon} alt="Remove" style={{ height: '25px', width: '25px' }} />
                </button>
                </TableCell>
              </TableRow>
            ))}
            </TableBody>
            )
            : (null) }
          </Table>
        </TableContainer>
        </div>
      <div className={styles.flexico}>
        <span style={{ fontSize: '14px' }} className={styles.heading}>
        {videoFiles?.length}
        {' '}
        Videos added
        </span>
        { videoFiles.length < MAX_VIDEOS
        && (
          <div className={styles.flexButton}>
            <label htmlFor="video-upload" className={styles.labelButton}>
              Add Files
            </label>
            <input onChange={handleFileChange} type="file" accept="video/*" multiple id="video-upload" className={styles.hiddenInput} />
          </div>
        )}
        <div className={styles.flexButtun}>
          <button
          onClick={() => { setOpen(false); setSelectedFiles([]); }}
          className={styles.cancel}
          >
            Cancel
          </button>
          <button
            disabled={isLoading}
            className={styles.save}
            style={{ cursor: isLoading ? 'default' : 'pointer', opacity: isLoading ? '0.5' : '1' }}
            onClick={handleInitMultipartUpload}
          >
              {isLoading ? <Spinner spinex={spinerSmall} /> : buttonText}
          </button>
        </div>
      </div>
      </div>
    </div>
  );
}
