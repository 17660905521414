/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import AXIOS from 'axios';
import cn from 'classnames';
import moment from 'moment';
import useSWR from 'swr';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Menu, makeStyles, Slider, withStyles, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AppContext } from 'Context';
import { Pathname } from 'Routes';
import { Page, ProjectorShimmer } from 'Components';
import { dataFetcher, endpoints, AxiosCancelToken, baseUrl } from 'Api';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import EditIcon from '@material-ui/icons/Edit';
import AddAPhotoOutlinedIcon from '@material-ui/icons/AddAPhotoOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TransformIcon from '@material-ui/icons/Transform';
import { ReactComponent as DragIcon } from 'Assets/drag.svg';
import RotatingLoader from 'Components/RotatingLoader';
import { ReactComponent as BackIcon } from 'Assets/chevron-left.svg';
import AlterImage from 'Assets/alter-image.png';
import styles from './index.module.css';
import { AddPhotoModal } from './component/AddPhotoModal';
import LoaderContent from '../../../Components/upload-modal/model-dialog-content/LoaderContent';
import { UploadModal } from './component/loaderModal';
import { transitionType } from '../../../Commons';
import { EditModal } from '../videos/components/upload-modal';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const useStyles = makeStyles((theme) => ({
  playerWrapper: {
    top: '388px'
  }
}));

const PrettoSlider = withStyles({
  root: {
    width: 114,
    // verticalAlign: 'middle',
    marginLeft: '-10px',
    '& .MuiSlider-rail': {
      opacity: 0.2,
      // backgroundColor: ' #1976d2',
    },
    '& .MuiSlider-mark': {
      // color: ' #1976d2',
      '& .MuiSlider-markActive': {
        opacity: 0.8,
        // backgroundColor: '#3f51b5 !important',
      },
    },

  },

})(Slider);

const ContentGroup = ({ id: layoutID, groups, groupType, onChange, setData, getPlaylist, layoutType }) => {
    const { setAppSnackbar } = useContext(AppContext);
    const initialItems = groupType === '3' ? groups?.photos : groups?.videos;
  const [items, setItems] = useState(initialItems);
  const [firstImage, setFirstImage] = useState();

  useEffect(() => {
    if (groupType !== '3') {
      onChange(firstImage, null);
    }
  }, [firstImage]);
  useEffect(() => {
    if (groupType !== '3') {
      items
        ? setFirstImage(items[0].thumbnails[0]) : null;
    }
  }, [items]);
 const [orderLoading, setOrderLoading] = useState(false);
  const [newOrder, updateNewOrder] = useState();
  const { isValidating: updatingNewOrder, mutate: updateOrder } = useSWR([
    groupType === '1' ? endpoints.updateCategoryListVideoOrder : groupType === '2'
      ? endpoints.updatePlayListVideoOrder : endpoints.reOrderMyPhotos,
    newOrder?.items,
    layoutID,
  ], {
    fetcher: (url, newItems, currentLayoutID) => dataFetcher(url, {
      ...(groupType === '2') && { playlist_id: currentLayoutID },
      ...(groupType === '1') && { category_id: currentLayoutID },
      items: newItems,
    }),
    onSuccess: ({ success }) => {
      if (success) {
        setAppSnackbar({
          isVisible: true,
          message: 'Order updated',
        });
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while updating your order',
        });
      }
      setOrderLoading(false);
    },
    onError: () => {
    setOrderLoading(false);
    },
  });
  const [selectedVideoId, setVideoId] = useState(undefined);
  const [selectedPhotoId, setPhotoId] = useState(undefined);

  const [videoRemoveData, setVideoRemoveData] = useState({ catId: layoutID, videoId: selectedVideoId });
  const { mutate: deleteCategoryFromVideo } = useSWR([
    endpoints.deleteCategoryFromVideo,
    videoRemoveData,
  ], {
    fetcher: (url, data) => dataFetcher(url, { ...data }),
    onSuccess: ({ success }) => {
      if (success) {
        getPlaylist();
        setVideoId(undefined);
        onChange(firstImage, 'category');
        setAppSnackbar({
          isVisible: true,
          message: 'Video Removed',
        });
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while updating your order',
        });
      }
    },
    onError: () => { },
  });

  const { mutate: deletePlaylistFromVideo } = useSWR([
    endpoints.deletePlaylistFromVideo,
    videoRemoveData,
  ], {
    fetcher: (url, data) => dataFetcher(url, { ...data }),
    onSuccess: ({ success }) => {
      if (success) {
        getPlaylist();
        setVideoId(undefined);
        onChange(firstImage, 'category');
        setAppSnackbar({
          isVisible: true,
          message: 'Video Removed',
        });
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while updating your order',
        });
      }
    },
    onError: () => { },
  });

  const { mutate: removePhotoFromAlbum } = useSWR([
    endpoints.deleteMyPhoto,
    selectedPhotoId,
  ], {
    fetcher: (url, photo_id) => dataFetcher(url, { photo_id }),
    onSuccess: ({ success }) => {
      if (success) {
        const index = items.findIndex((item) => item.id === selectedPhotoId);
        if (index !== -1) {
          items.splice(index, 1);
        }
        setData({ ...groups, photos: items, itemsCount: groups.itemsCount - 1 });
        setPhotoId(undefined);
        setAppSnackbar({
          isVisible: true,
          message: 'Photo Removed',
        });
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while removing photo',
        });
      }
    },
    onError: () => {
      setAppSnackbar({
        isVisible: true,
        type: 'error',
        message: 'Oops! Something went wrong while removing photo',
      });
    },
  });

  useEffect(() => {
    setVideoRemoveData(layoutType === '1' ? { catId: layoutID, videoId: selectedVideoId } : layoutType === '2' ? { playlist_id: layoutID, videoId: selectedVideoId } : null);
  }, [selectedVideoId]);

  useEffect(() => {
    if (selectedVideoId) {
      layoutType === '1' ? deleteCategoryFromVideo() : layoutType === '2' ? deletePlaylistFromVideo() : null;
    }
  }, [videoRemoveData]);

  useEffect(() => {
    if (selectedPhotoId) {
      removePhotoFromAlbum();
    }
  }, [selectedPhotoId]);

  const [removalStatus, setRemovalStatus] = useState({});
  const cancelOrder = () => {
    updateNewOrder(undefined);
    setItems(initialItems);
  };
  const removePhoto = (selectedId) => {
    setRemovalStatus((prevStatus) => ({
      ...prevStatus,
      [selectedId]: true, // Fix the variable name here
    }));
    setPhotoId(selectedId);
  };

  const handleRemove = (selectedVid) => {
    setRemovalStatus((prevStatus) => ({
      ...prevStatus,
      [selectedVid]: true,
    }));
    setVideoId(selectedVid);
  };

  const onDragEnd = (result) => {
    if (!result.destination) { return; }
    const newItems = reorder(items, result.source.index, result.destination.index);
    const changedItems = {};

    // items?.forEach((item, idx) => {
    //   if (item?.id !== newItems[idx]?.id) {
    //     changedItems[newItems[idx]?.id] = (idx + 1);
    //   }
    // }); // this is for if only need to send order of specific items which changed. Dont remove this code.
    newItems?.forEach((item, idx) => {
      changedItems[item?.id] = idx + 1;
    });

    if (result.source.index !== result.destination.index) {
      updateNewOrder({ items: changedItems });
    }

    setItems(newItems);
  };

  // useEffect(() => { if (newOrder) { setOrderLoading(true); updateOrder(); } }, [newOrder]);
  return (
    <div className={styles.contentGroup}>
      {newOrder !== undefined
      && (
            <div className={styles.accessedCompny}>
              <button onClick={() => { setOrderLoading(true); updateNewOrder(undefined); updateOrder(); }} className={styles.existButtun}>
                Update
              </button>
              <button onClick={() => cancelOrder()} className={styles.discard}>
                Discard
              </button>
            </div>
      )}
      { orderLoading === true && <RotatingLoader />}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(droppableProvided, droppableSnapshot) => (
            <div
              className={styles.contentGroupBody}
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              { groupType !== '3' && items?.map(({ id, description, title, thumbnails: images, visibility
 }, idx) => (
                <Draggable key={id} draggableId={id} index={idx}>
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      className={cn({
                        [styles.contentGroupBodyRow]: true,
                        [styles.dragged]: draggableSnapshot.isDragging,
                      })}
                      key={idx}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      style={draggableProvided.draggableProps.style}
                    >
                      <div className={styles.contentGroupBodyCell}>
                        <div className={styles.categoryContainer}>
                          <div className={styles.categoryContainerImages}>
                            <div className={styles.dragIconContainer} {...draggableProvided.dragHandleProps}>
                              <DragIcon className={styles.dragIcon} />
                            </div>
                            <div className={styles.imageContainer}>
                              <img
                                src={images[0] || AlterImage}
                                alt={title}
                                className={styles.image}
                                onError={(e) => {
                                  e.target.src = AlterImage;
                                }}
                              />
                              {/* {setFirstImage(items[0].images[0])} */}
                              {!firstImage ? setFirstImage(images[0]) : null}
                            </div>
                            <div className={styles.contentGroupTextContainer}>
                              <div className={styles.contentGroupTitle}>{title}</div>
                              <div className={styles.contentGroupDescription}>{description}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ textAlign: 'center' }} className={styles.contentGroupBodyCell}>
                        <div>
                        <p
                          className={styles.visibility}
                           style={{ color: visibility !== '0' ? '#1172d2' : 'red' }}
                        >
                          {visibility === '0' ? 'Draft' : visibility === '1' ? 'Private' : visibility === '2' ? 'Published' : visibility === '3' ? 'Group' : '' }
                        </p>
                        <button
                          key={id}
                          onClick={() => !removalStatus[id] && handleRemove(id)}
                          className={removalStatus[id] ? styles.removeButtonRuniing : styles.removeButton}
                        >
                          {removalStatus[id] ? 'Removing' : 'Remove'}
                        </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              { groupType === '3' && items.map(({ id, imagekit_url, photo_file, visibility }, idx) => (
                <Draggable key={id} draggableId={id} index={idx}>
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      className={cn({
                        [styles.contentGroupBodyRow]: true,
                        [styles.dragged]: draggableSnapshot.isDragging,
                      })}
                      key={idx}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      style={draggableProvided.draggableProps.style}
                    >
                      <div className={styles.contentGroupBodyCell}>
                        <div className={styles.categoryContainer}>
                          <div className={styles.categoryContainerImages}>
                            <div className={styles.dragIconContainer} {...draggableProvided.dragHandleProps}>
                              <DragIcon className={styles.dragIcon} />
                            </div>
                            <div className={styles.imageContainer}>
                              <img
                                src={imagekit_url || photo_file || AlterImage}
                                className={styles.image}
                                onError={(e) => {
                                  e.target.src = AlterImage;
                                }}
                              />
                              {/* {setFirstImage(items[0].images[0])} */}
                              {!firstImage ? setFirstImage(photo_file) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ textAlign: 'center' }} className={styles.contentGroupBodyCell}>
                        <div>
                        {/* <p
                          className={styles.visibility}
                           style={{ color: visibility !== '0' ? '#1172d2' : 'red' }}
                        >
                          {visibility === '0' ? 'Draft' : visibility === '1' ? 'Private' : visibility === '2' ? 'Published' : visibility === '3' ? 'Group' : '' }
                        </p> */}
                        <button
                          onClick={() => removePhoto(id)}
                          className={removalStatus[id] ? styles.removeButtonRuniing : styles.removeButton}
                        >
                          {removalStatus[id] ? 'Removing' : 'Remove'}
                        </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>

      </DragDropContext>
    </div>
  );
};

export const ContentLayoutDashboard = () => {
  const { setAppSnackbar, langText, setaddMorePhoto } = useContext(AppContext);
  const layoutDashboardText = langText?.layout;
  const commonText = langText?.common;
  const navigate = useNavigate();
  const { id, layoutType } = useParams();
  const classes = useStyles();
  const location = useLocation();
  const [prevTitle, setPrevTitle] = useState(undefined);
  const [title, setTitle] = useState(undefined);
  const [isDisabled, setDisabled] = useState(true);
  const [categoryData, setCategoryData] = useState(undefined);
  const [playlistData, setPlaylistData] = useState(undefined);
  const [albumData, setAlbumData] = useState(undefined);
  const [addPhotoModal, setAddPhotoModal] = useState(false);
  const [multiplePhoto, setMultiplePhoto] = useState(undefined);
  const [photoFile, setPhotoFile] = useState([]);
  const [albumUploadData, setAlbumUploadData] = useState(undefined);
  const [openBackGroundMenu, setBackgroundMenu] = useState(null);
  const [backGroundImages, setBackgroundImages] = useState(undefined);
  const [selectedBackGroundImage, selectBackGroundImage] = useState(undefined);
  const [defaultBackGroundImage, setDefaultBackGroundImage] = useState(undefined);
  const [photoUploadPercentage, setPhotoUploadPercentage] = useState(0);
  const [photoUploadingCompleted, setPhotoUploadingCompleted] = useState(false);
  const [contentType, setContentType] = useState(undefined);
  const [albumPublished, setAlbumPublishStatus] = useState(false);
  const [albumStatus, setAlbumStatus] = useState('Album Updated');
  const [heading, setHeading] = useState(undefined);
  const [settingsType, setSettingsType] = useState(undefined);
  const [selectedTransitionType, selectTransitionType] = useState(transitionType[0].class);
  const [transitionSpeed, setTransitionSpeed] = useState(30);
  const [updatingSettings, setUpdatingSettings] = useState(false);
  const [openEditVideoModal, setEditVideoModal] = useState(false);
  const contentTypeNumber = localStorage.getItem('content_type');
  const [loading, setLoading] = useState(false);
  const [orderNumber, setOrderNUmber] = useState();
  const { isValidating: gettingCategory, mutate: getCategory } = useSWR([endpoints.getMyCategory, id], {
    fetcher: (url, category_id) => dataFetcher(url, { category_id }),
    onSuccess: ({ success, data }) => {
      if (success) {
        const requiredData = data[0];
        let viewsCount = 0;
        requiredData?.videos?.forEach(({ views }) => { viewsCount += parseInt(views, 10); });

        setCategoryData({ ...requiredData, itemsCount: requiredData?.video_count, viewsCount });
        setTitle(requiredData?.title);
        setPrevTitle(requiredData?.title);
        setOrderNUmber(requiredData?.order_number);
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });
  const getToken = () => localStorage.getItem('token');
  const axios = AXIOS.create({ baseURL: baseUrl.URL, method: 'POST' });
  axios.interceptors.request.use((req) => {
    if (req?.data instanceof FormData) {
      const formData = req?.data;

      if (formData.has('token')) { formData.delete('token'); }

      formData.append('token', getToken());

      return ({ ...req, data: formData });
    }

    return ({ ...req, data: { ...req.data, token: getToken() } });
  });

  // const dataFetcher2 = (url, data, cancelToken) => axios({
  //   url,
  //   ...data && { data },
  //   onUploadProgress: (ProgressEvent) => {
  //     const { loaded, total } = ProgressEvent;
  //     const percentage = Math.floor((loaded * 100) / total);
  //     if (percentage < 98) {
  //       setPhotoUploadPercentage(percentage);
  //     } else {
  //       setPhotoUploadPercentage(98);
  //     }
  //   },
  //   ...cancelToken && { cancelToken },

  // }).then((res) => res.data);

  const { isValidating: gettingPlaylist, mutate: getPlaylist } = useSWR([endpoints.getMyPlaylist, id], {
    fetcher: (url, playlist_id) => dataFetcher(url, { playlist_id }),
    onSuccess: ({ success, data }) => {
      if (success) {
        const requiredData = data[0];
        let viewsCount = 0;

        requiredData?.videos?.forEach(({ views }) => { viewsCount += parseInt(views, 10); });

        setPlaylistData({ ...requiredData, itemsCount: requiredData?.video_count, viewsCount });
        setTitle(requiredData?.title);
        setPrevTitle(requiredData?.title);
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });

  const { isValidating: gettingAlbum, mutate: getAlbum } = useSWR([endpoints.getMyAlbumDetail, id], {
    fetcher: (url, album_id) => dataFetcher(url, { album_id }),
    onSuccess: ({ success, data, transition_speed, transition_type }) => {
      if (success) {
        const requiredData = data[0];
        let viewsCount = 0;
        requiredData?.photos?.forEach(({ views }) => { viewsCount += parseInt(views, 10); });
        setAlbumData({ ...requiredData, itemsCount: requiredData?.photo_count, viewsCount });
        setTitle(requiredData?.title);
        setPrevTitle(requiredData?.title);
        setTransitionSpeed(parseInt(transition_speed, 10));
        selectTransitionType(transition_type);
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong' });
    },
  });

  const { isValidating: updatingCategory, mutate: updateCategory } = useSWR([endpoints.addEditCategory, id, title, orderNumber], {
    fetcher: (url, category_id, inputTitle, orderNumber) => dataFetcher(url, { category_id, title: inputTitle, order_number: orderNumber }),
    onSuccess: ({ success }) => {
      if (success) {
        setPrevTitle(title);
        setAppSnackbar({
          isVisible: true,
          message: 'Category title was updated',
        });
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while updating title',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while updating title' });
    },
  });

  const handleBackGroundModal = () => {
    setBackgroundMenu(null);
  };

  const { isValidating: updatingBackGround, mutate: updateBackGround } = useSWR(
    [endpoints.addEditCategory, id, selectedBackGroundImage, orderNumber], {
      fetcher: (url, category_id, icon) => dataFetcher(url, { category_id, icon, order_number: orderNumber }),
      onSuccess: ({ success }) => {
        if (success) {
          handleBackGroundModal();
          setAppSnackbar({
            isVisible: true,
            message: 'Category background was updated',
          });
          getCategory();
        } else {
          setAppSnackbar({
            isVisible: true,
            type: 'error',
            message: 'Oops! Something went wrong while updating background',
          });
        }
      },
      onError: () => {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while updating background' });
      },
    }
  );

  const { isValidating: updatingPlaylist, mutate: updatePlaylist } = useSWR([endpoints.addEditPlaylist, id, title], {
    fetcher: (url, playlist_id, inputTitle) => dataFetcher(url, { playlist_id, title: inputTitle }),
    onSuccess: ({ success }) => {
      if (success) {
        setPrevTitle(title);
        setAppSnackbar({
          isVisible: true,
          message: 'Playlist title was updated',
        });
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while updating title',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while updating title' });
    },
  });

  const { isValidating: gettingBackgroundImages, mutate: getBackGroundImages } = useSWR(endpoints.getBackgroundImages, {
    onSuccess: ({ success, data }) => {
      if (success) {
        setBackgroundImages(data.filter((item) => parseInt(item.is_default, 10) === 0));
        setDefaultBackGroundImage(data.filter((item) => parseInt(item.is_default, 10) === 1)[0]);
      }
    },
    onError: () => { },
  });

  const hideModal = () => {
    setAddPhotoModal(false);
    setPhotoFile([]);
  };

  const hideEditModal = () => {
    setEditVideoModal(false);
  };

  const { isValidating: updatingAlbum, mutate: updateAlbum } = useSWR([endpoints.addEditAlbum, id, title], {
    fetcher: (url, album_id, inputTitle) => dataFetcher(url, { album_id, title: inputTitle }),
    onSuccess: ({ success }) => {
      if (success) {
        setPrevTitle(title);
        setAppSnackbar({
          isVisible: true,
          message: 'Photo Album title was updated',
        });
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while updating title',
        });
      }
    },
    onError: () => {
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while updating title' });
    },
  });

  const hideUploadModal = () => {
    setAlbumPublishStatus(false);
    navigate(location.pathname);
  };

  const { isValidating: addingPhotos, mutate: addPhotoContent } = useSWR([
    endpoints.addPhotoContent, albumUploadData], {
    fetcher: (url, formData) => dataFetcher(url, formData),
    onSuccess: ({ success }) => {
      setaddMorePhoto(false);
      if (success) {
        hideModal();
        getAlbum();
      } else {
        setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong!' });
      }
      setTimeout(() => {
        setPhotoUploadPercentage(photoUploadPercentage + 1);
      }, 0);
      setTimeout(() => {
        setPhotoUploadPercentage(100);
      }, 500);
      setTimeout(() => {
        setPhotoUploadingCompleted(true);
      }, 1000);
    },
    onError: (error) => {
      const responseData = error?.response?.data;
      setaddMorePhoto(false);
      setAppSnackbar({ isVisible: true, type: 'error', message: responseData?.message || 'Oops! Something went wrong!!' });
      if (responseData?.message === 'Storage Space Exceeded') {
        hideModal();
        hideUploadModal();
        navigate(Pathname.upgradePlan);
      }
      setTimeout(() => {
        setPhotoUploadPercentage(photoUploadPercentage + 1);
      }, 0);
      setTimeout(() => {
        setPhotoUploadPercentage(100);
      }, 500);
      setTimeout(() => {
        setPhotoUploadingCompleted(true);
      }, 1000);
    },
  });

  const { isValidating: savingSettings, mutate: saveSettings } = useSWR([endpoints.settings, settingsType, id, settingsType === 1 ? transitionSpeed : selectedTransitionType], {
    fetcher: (url, id, album_id, value) => dataFetcher(url, { album_id, id, value }),
    onSuccess: ({ success }) => {
      setSettingsType(undefined);
      if (success) {
        // getAlbum();
        setAppSnackbar({
          isVisible: true,
          message: 'Album settings updated',
        });
      } else {
        setAppSnackbar({
          isVisible: true,
          type: 'error',
          message: 'Oops! Something went wrong while updating settings',
        });
      }
      setUpdatingSettings(false);
    },
    onError: () => {
      setUpdatingSettings(true);
      setAppSnackbar({ isVisible: true, type: 'error', message: 'Oops! Something went wrong while updating settings' });
    },
  });

  useEffect(() => {
    if (layoutType === '1') {
      getCategory();
      setHeading(layoutDashboardText?.manageCategory || 'Manage Category');
    } else if (layoutType === '2') {
      getPlaylist();
      setHeading(layoutDashboardText?.managePlaylist || 'Manage Playlist');
    } else {
      getAlbum();
      setHeading(layoutDashboardText?.manageAlbum || 'Manage Album');
    }
  }, []);
  const [mainImage, setMainImage] = useState(null);
  const handelCategoryOnChange = (image, category) => {
    setMainImage(image);
    if (category === 'category') {
      getCategory();
    }
  };

  const selectPhoto = (e) => {
    const selectedFiles = e.target.files;
    setMultiplePhoto(selectedFiles);
    const photoData = [];
    if (selectedFiles) {
      Array.from(selectedFiles).forEach((file) => {
        photoData.push({ 'url': URL.createObjectURL(file), 'file': file });
      });
      const photoArray = [...photoFile]; // make a separate copy of the array
      photoArray.push(...photoData);
      setPhotoFile(photoArray);
      setAddPhotoModal(true);
    }
  };

  const removePhoto = (index) => {
    const photoArray = [...photoFile]; // make a separate copy of the array
    photoArray.splice(index, 1);
    setPhotoFile(photoArray);
  };

  const uploadPhoto = () => {
    const formData = new FormData();
    formData.append('album_id', id);
    photoFile.forEach((photo) => {
      formData.append('photo_file[]', photo.file);
    });
    setAlbumUploadData(formData);
  };

  useEffect(() => {
    if (albumUploadData) {
      setContentType('album');
      setAlbumPublishStatus(true);
      addPhotoContent();
    }
  }, [albumUploadData]);

  useEffect(() => {
    getBackGroundImages();
  }, []);

  const handleBackGroundClick = (event) => {
    setBackgroundMenu(event.currentTarget);
    // getBackGroundImages();
  };

  const openEditModalHandler = () => {
    setEditVideoModal(true);
  };

  useEffect(() => {
    if (selectedBackGroundImage) {
      updateBackGround();
    }
  }, [selectedBackGroundImage]);

  const transitionChange = (event) => {
    setSettingsType(2);// changing transition speed
    selectTransitionType(event.target.value);
  };

  const valuetext = (value) => `${value}°C`;

  const transitionSpeedHandler = (event, newValue) => {
    setTransitionSpeed(newValue);
    setSettingsType(1); // changing transition speed
  };

  useEffect(() => {
    if (gettingBackgroundImages || gettingAlbum || gettingCategory || gettingPlaylist) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [gettingBackgroundImages, gettingAlbum, gettingCategory, gettingPlaylist]);
  const backToLocation = window.location.pathname.split('/')[1];
  return (
    <Page className={styles.page} contentClassName={styles.pageContent} noFixedSidebar>
      {
        loading || gettingAlbum || gettingCategory || gettingBackgroundImages ? <ProjectorShimmer isLight /> : (
          <>
            <div className={styles.preview}>
              <div className={styles.previewContent}>
                <div className={styles.backTitleContainer}>
                  <div
                    aria-hidden="true"
                    className={styles.backButton}
                    onClick={() => { navigate(`/${backToLocation}`); }}
                  >
                    {commonText?.back || 'Back'}
                  </div>
                  <div className={styles.header}>
                    <BackIcon className={styles.videoBackButton} onClick={() => { navigate(-1); }} />
                  </div>
                  <div
                    style={{ border: 'none', background: 'transparent', fontWeight: '600' }}
                    className={cn({
                      [styles.previewTitle]: true,
                      [styles.positionStyle]: true,
                    })}
                  >
                    {heading}
                  </div>
                </div>
                {
                  (layoutType === '1' || layoutType === '3') ? (
                    <div
                      style={{
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),
                        url(${layoutType === '1' ? (categoryData?.icon ? categoryData?.icon : defaultBackGroundImage?.image) : albumData?.imagekit_icon || albumData?.icon})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        fontWeight: '700',
                      }}
                      className={styles.previewImageBack}
                    >
                      {layoutType === '1' ? categoryData?.title : albumData?.title}
                    </div>
                  ) : null
                }
                {
                  layoutType === '1' || layoutType === '3' ? (
                    <>
                      <div className={styles.editBackground} onClick={layoutType === '1' ? handleBackGroundClick : openEditModalHandler}>{ layoutType === '1' ? (layoutDashboardText?.editBackground || 'Edit background') : 'Edit Thumbnail'}</div>
                      <Menu
                        id="background-menu"
                        anchorEl={openBackGroundMenu}
                        // keepMounted
                        open={Boolean(openBackGroundMenu)}
                        onClose={handleBackGroundModal}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                        PaperProps={{
                          style: {
                            marginTop: '110px'
                          }
                        }}
                        className={classes.playerWrapper}
                        PopoverClasses={{ paper: styles.dropDownAnimation }}
                      >
                        <div className={styles.backgroundContainer}>
                          <div className={styles.backClose}>
                            <CloseIcon onClick={handleBackGroundModal} />
                          </div>
                          <div className={styles.backgroundImageList}>
                            {
                              gettingBackgroundImages ? <div className={styles.loaderContent}>Loading ...</div> : (
                                backGroundImages?.map(({ image, id }) => ((
                                  <div
                                    className={styles.backGroundImage}
                                    onClick={() => { selectBackGroundImage(id); }}
                                  >
                                    <img
                                      src={image || AlterImage}
                                      style={{
                                        cursor: 'pointer',
                                        border: `${selectedBackGroundImage === id ? '4px solid #5aa5ef' : ''}`,
                                        width: '177px',
                                        height: '62px',
                                        borderRadius: '1vh',
                                        margin: '5px auto'
                                      }}
                                      onError={(e) => {
                                        e.target.src = AlterImage;
                                      }}
                                    />
                                  </div>
                                )))
                              )
                            }
                          </div>
                          {/* <div className={styles.changeButton} onClick={() => { updateBackGround(); }}>
                            Change
                          </div> */}
                        </div>
                      </Menu>
                    </>
                  ) : null
                }
                <div className={styles.previewInputContainer}>
                  {gettingCategory
                    ? (
                      <div className={styles.previewTitleLoading}>{commonText?.loading || 'Loading ...'}</div>
                    )
                    : categoryData
                      ? (
                        <input
                          className={styles.previewTitle}
                          defaultValue={categoryData?.title || ''}
                          disabled={isDisabled}
                          onChange={(e) => { setTitle(e?.target?.value); }}
                        />
                      )
                      : null}
                  {gettingPlaylist
                    ? (
                      <div className={styles.previewTitleLoading}>{commonText?.loading || 'Loading ...'}</div>
                    )
                    : playlistData
                      ? (
                        <input
                          autoFocus={!isDisabled}
                          className={styles.previewTitle}
                          defaultValue={playlistData?.title || ''}
                          disabled={isDisabled}
                          onChange={(e) => { setTitle(e?.target?.value); }}
                        />
                      )
                      : null}
                  {gettingAlbum
                    ? (
                      <div className={styles.previewTitleLoading}>{commonText?.loading || 'Loading ...'}</div>
                    )
                    : albumData
                      ? (
                        <input
                          autoFocus={!isDisabled}
                          className={styles.previewTitle}
                          defaultValue={albumData?.title || ''}
                          disabled={isDisabled}
                          onChange={(e) => { setTitle(e?.target?.value); }}
                        />
                      )
                      : null}
                  {isDisabled
                    ? <EditIcon className={styles.previewIcon} onClick={() => { setDisabled(false); }} />
                    : (
                      <PublishIcon
                        className={styles.previewIcon}
                        onClick={() => {
                          if (title?.trim()) {
                            if (layoutType === '1' ? !updatingCategory : layoutType === '2'
                              ? !updatingPlaylist : !updatingAlbum) {
                              if (title !== prevTitle) {
                                if (layoutType === '1') {
                                  updateCategory();
                                } else if (layoutType === '2') {
                                  updatePlaylist();
                                } else {
                                  updateAlbum();
                                }
                              }
                              setDisabled(true);
                            }
                          }
                        }}
                      />
                    )}
                </div>
                <div className={styles.previewDetails}>
                  {(layoutType === '1' ? gettingCategory : layoutType === '2' ? gettingPlaylist : gettingAlbum)
                    ? (commonText?.loading || 'Loading ...')
                    : `${(layoutType === '1' ? categoryData : layoutType === '2'
                      ? playlistData : albumData)?.itemsCount} ${layoutType === '3' ? (albumData?.itemsCount > 1 ? layoutDashboardText?.photos || 'Photos' : layoutDashboardText?.photo || 'Photo') : ((layoutType === '2' ? playlistData : categoryData)?.itemsCount > 1) ? (layoutDashboardText?.videos || 'Videos') : (layoutDashboardText?.video || 'Video')}
                            • ${(layoutType === '1' ? categoryData : layoutType === '2'
                      ? playlistData : albumData)?.viewsCount}
                          ${((layoutType === '1' ? categoryData : layoutType === '2'
                        ? playlistData : albumData)?.viewsCount > 0) ? (layoutDashboardText?.views || 'Views') : (layoutDashboardText?.view || 'View')}
                          • ${(layoutDashboardText?.updated || 'Updated')} ${moment((layoutType === '1'
                          ? categoryData
                          : playlistData)?.updated).format('MM/DD')}`}
                </div>
                {
                  layoutType === '3' && (
                    <>
                      <div onClick={() => { setAddPhotoModal(true); }} className={styles.addMorePhotoContainer}>
                        {/* <AddAPhotoOutlinedIcon /> */}
                        <span style={{ cursor: 'pointer' }}>
                          <AddAPhotoOutlinedIcon className={styles.addMorePhotoIcon} />
                        </span>
                        <span className={styles.addMorePhoto}>{layoutDashboardText?.addPhotoToAlbum || 'Add photo to album'}</span>
                        <span style={{ cursor: 'pointer' }}>
                          <AddIcon className={styles.addMoreIcon} />
                        </span>
                      </div>
                    </>
                  )
                }
              </div>
            </div>
            <div className={styles.contentGroupContainer}>
              {(layoutType === '1' ? gettingCategory : layoutType === '2' ? gettingPlaylist : gettingAlbum)
                ? (
                  <div className={styles.loader}>
                    <div className={styles.loaderContent}>{commonText?.loading || 'Loading ...'}</div>
                  </div>
                )
                : (layoutType === '1' ? categoryData : playlistData)?.videos?.length
                  || (layoutType === '3' && albumData?.photos?.length)
                  ? (
                    <ContentGroup
                      id={id}
                      groups={(layoutType === '1' ? categoryData : layoutType === '2'
                        ? playlistData : albumData)}
                      groupType={layoutType}
                      onChange={(image, category) => handelCategoryOnChange(image, category)}
                      setData={(layoutType === '1' ? setCategoryData : layoutType === '2'
                        ? setPlaylistData : setAlbumData)}
                      getPlaylist={getPlaylist}
                      layoutType={layoutType}
                    />
                  )
                  : (
                    <div className={styles.dataText}>
                      {gettingAlbum || gettingCategory || gettingPlaylist ? (commonText?.loading || 'Loading...') : (
                        layoutType === '3' ? (layoutDashboardText?.noPhotoUploaded || 'No Photos uploaded yet.') : (layoutDashboardText?.noVideoUploaded || 'No Videos uploaded yet.')
                      )}
                    </div>
                  )}
            </div>
          </>
        )
      }
      {
        addPhotoModal && (
          <AddPhotoModal
            isVisible={addPhotoModal}
            removePhoto={removePhoto}
            selectPhoto={selectPhoto}
            photoFile={photoFile}
            uploadPhoto={uploadPhoto}
            onHide={hideModal}
            addingPhotos={addingPhotos}
          />
        )
      }
      <UploadModal
        isVisible={albumPublished}
        onHide={hideUploadModal}
        contentType={contentType}
        videoUploadingCompleted={photoUploadingCompleted}
        videoUploadPercentage={photoUploadPercentage}
        albumStatus={albumStatus}
      />
      <EditModal isVisible={openEditVideoModal} onHide={hideEditModal} contentDetails={albumData} uploadType="photo" />
    </Page>
  );
};

// "API: to update order of video under playlist
// updatePlayListVideoOrder
// params:
// token, items and  playlist_id
// items = {"video_id":"order_number"}"
